import { buscarProduto } from "./funcoes/buscarProduto";
import { gerarCfo } from "./funcoes/gerarCfo";
import { calcularImpostoAproximado } from "./funcoes/calcularImpostoAproximado";
import { gerarCfoEntrada } from "./funcoes/gerarCfoEntrada";

export const gerarDet = async (operacaoOpcao, itens, ide, dest, interestadual) => {
  try {
		let det = [];
		let impostoNacional = 0
		let impostoImportado = 0
		let impostoEstadual = 0
		let impostoMunicipal = 0
		let valorTotalImpostoAproximado = 0
		let valorTotalProduto = 0

		for (let index = 0; index < itens.length; index++) {
			const item = itens[index];

			const itemValorProduto = operacaoOpcao.devolver ? (item.valorUnitarioComercial || item.prod.vUnCom) * (item.quantidadeComercial || item.prod.qCom) : item.valorProduto;
			
			const produtoResponse = await buscarProduto(item.notaId ? item.prod.produtoId : item.produtoId);
			const produto = produtoResponse.data;
	
			let cfo = operacaoOpcao.devolver ? gerarCfoEntrada(produto.tributacao.csosn, ide, dest) : gerarCfo(produto.tributacao.csosn, ide, dest);

			valorTotalProduto += itemValorProduto;
			let vImpostoAproximado;

			if (produto.tributacao.ncm !== '') {
				vImpostoAproximado = await calcularImpostoAproximado(produto.tributacao.ncm, itemValorProduto, 0);
				
				impostoNacional += vImpostoAproximado.impostoNacional;
				impostoImportado += vImpostoAproximado.impostoImportado;
				impostoEstadual += vImpostoAproximado.impostoEstadual;
				impostoMunicipal += vImpostoAproximado.impostoMunicipal;
				valorTotalImpostoAproximado += vImpostoAproximado.valorTotalImpostoAproximado;
			};			
	
			if (item.quantidadeComercial > 0 || item.prod.qCom > 0)
				det.push({
						nItem: index + 1,
						id: operacaoOpcao.alterar ? item.id : undefined,
						prod: {
								id: operacaoOpcao.alterar ? item.prod.id : undefined,
								produtoId: item.produtoId || produto.id, 
								cProd: produto.codigo || item.produtoId || produto.id,
								cEAN: "SEM GTIN",
								xProd: produto.nome,
								NCM: produto.tributacao.ncm || undefined,
								CEST: produto.tributacao.cest || undefined,
								CFOP: (ide?.NFref?.refNFe || operacaoOpcao.converter) && !operacaoOpcao.devolver ? (interestadual ? "6929" : "5929") : cfo,
								uCom: produto.embalagem.unidade,
								qCom: item.quantidadeComercial || item.prod.qCom,
								vUnCom: item.valorUnitarioComercial || item.prod.vUnCom,
								vProd: itemValorProduto  || item.prod.vProd,
								cEANTrib: "SEM GTIN",
								uTrib: produto.embalagem.unidade,
								qTrib: item.quantidadeComercial || (operacaoOpcao.devolver ? item.prod.qCom : item.prod.qTrib),
								vUnTrib: item.valorUnitarioComercial || item.prod.vUnTrib,
								vDesc: item.notaId ? item.prod.vDesc : item.valorDesconto,
								indTot: '1',
								vFrete: 0,
								vSeg: 0,
								vOutro: 0,
						},
						imposto: {
							id: operacaoOpcao.alterar ? item.imposto.id : undefined,
							vTotTrib: vImpostoAproximado.valorTotalImpostoAproximado,
							ICMS: {
								id: operacaoOpcao.alterar ? item.imposto.ICMS.id : undefined,
								orig: "0",
								CSOSN: produto.tributacao.csosn
							},
							PIS: {
								CST: produto.tributacao.cstPisCofins,
								vBC: 0,
								pPIS: 0,
								vPIS: 0
							},
							COFINS: {
								CST: produto.tributacao.cstPisCofins,
								vBC: 0,
								pCOFINS: 0,
								vCOFINS: 0
							}
						}
				});
		}
	
      
		let observacaoTributos = ''

		if (valorTotalImpostoAproximado > 0) {
			observacaoTributos = `Val. aprox. dos Tributos: R$ ${valorTotalImpostoAproximado}`;

			if (impostoNacional > 0) {
				const varAux = parseFloat((impostoNacional / valorTotalProduto) * 100).toFixed(2)
				observacaoTributos = observacaoTributos +  ` Nacional (${varAux}%) R$ ${impostoNacional.toFixed(2)}`
			}
			
			if (impostoImportado > 0) {
				const varAux = parseFloat((impostoImportado / valorTotalProduto) * 100).toFixed(2)
				observacaoTributos = observacaoTributos +  ` Importado (${varAux}%) R$ ${impostoImportado.toFixed(2)}`
			}
			
			if (impostoEstadual > 0) {
				const varAux = parseFloat((impostoEstadual / valorTotalProduto) * 100).toFixed(2)
				observacaoTributos = observacaoTributos +  ` Estadual (${varAux}%) R$ ${impostoEstadual.toFixed(2)}`
			}

			if (impostoMunicipal > 0) {
				const varAux = parseFloat((impostoMunicipal / valorTotalProduto) * 100).toFixed(2)
				observacaoTributos = observacaoTributos +  ` Municipal (${varAux}%) R$ ${impostoMunicipal.toFixed(2)}`
			}      
		}		

    return {
			det,
			observacaoTributos,
			valorTotalImpostoAproximado
    }
	} catch (error) {			
			throw error;
	}
};
