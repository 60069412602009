import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  FormControlLabel,
  Switch,
  Paper,
  IconButton,
  TextField,
} from "@mui/material";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { i18n } from "../../translate/i18n";
import { FlexTextField, FlexFormatField, FlexSelectField, FlexNumericField } from "../input/Fields/FlexField";
import api from "../../services/api";
import CameraModal from "../CameraModal";
import MainHeaderFX from "../MainHeaderFx";
import NcmSearchModal from "../NcmSearchModal/index.js";
import toastError from "../../errors/toastError.js";
import { flattenFormikValidationErrors } from "../../utils/formatacao/flattenFormikValidationErrors.js";
import DialogModal from "../DialogModal";

import "./style.css";
import { height, shouldForwardProp, width } from "@mui/system";

const ProdutoModal = ({ open, onClose, sortFunction, produtoId, produtos}) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const initialState = {
    nome: "",
    status: true,
    embalagemId: "",
    preco: "",
    arquivoFoto: null,
    urlFoto: "",
    tributacao: {
      csosn: "",
      cstPisCofins: "",
      ncm: "",
      cest: "",
      tributacaoPadrao: true,
    },
  };

  const validationSchema = Yup.object().shape({
    nome: Yup.string().required("Campo obrigatório"),
    embalagemId: Yup.string().required("Campo obrigatório"),
    preco: Yup.string().required("Campo obrigatório"),
    tributacao: Yup.object().shape({
      csosn: Yup.string().required("Campo obrigatório"),
      cstPisCofins: Yup.string().required("Campo obrigatório"),
      ncm: Yup.string().required("Campo obrigatório"),
    }),
  });

  const isSmallScreen = useMediaQuery("(max-width:825px)");
  const [produto, setProduto] = useState(initialState);
  const [empresaId, setEmpresaId] = useState(-1);
  const [empresaProducaoPropria, setEmpresaProducaoPropria] = useState(false);
  const [embalagens, setEmbalagens] = useState([]);
  const [cameraModalOpen, setCameraModalOpen] = useState(false);
  const [ncmSearchModalOpen, setNcmSearchModalOpen] = useState(false);

  const [defaultTaxation, setDefaultTaxation] = useState(null);
    
  const arrayCsosn = [
    /*{ csosn: "101", descricao: "101 - Tributada pelo Simples Nacional com permissão de crédito"},*/
    { csosn: "102", descricao: "102 - Tributada pelo Simples Nacional sem permissão de crédito"},
    { csosn: "103", descricao: "103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta"},
    /*{ csosn: "201", descricao: "201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária"},
    { csosn: "202", descricao: "202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária"},
    { csosn: "203", descricao: "203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária"},*/
    { csosn: "300", descricao: "300 - Imune" },
    { csosn: "400", descricao: "400 - Não tributada pelo Simples Nacional" },
    { csosn: "500", descricao: "500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação"},
    /*{ csosn: "900", descricao: "900 - Outros" }*/
  ];

  const arrayCsosnProducaoPropria = [
    { csosn: "102", descricao: "102 - Tributada pelo Simples Nacional sem permissão de crédito"},
    { csosn: "103", descricao: "103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta"},
    { csosn: "300", descricao: "300 - Imune" },
    { csosn: "400", descricao: "400 - Não tributada pelo Simples Nacional" }
  ];

  const arrayPisCofins = [
    { cst: "01", descricao: "01 - Operação Tributável com Alíquota Básica" },
    { cst: "02", descricao: "02 - Operação Tributável com Alíquota Diferenciada"},
    { cst: "03", descricao: "03 - Operação Tributável com Alíquota por Unidade de Medida de Produto"},
    { cst: "04", descricao: "04 - Operação Tributável Monofásica - Revenda a Alíquota Zero"},
    { cst: "05", descricao: "05 - Operação Tributável por Substituição Tributária"},
    { cst: "06", descricao: "06 - Operação Tributável a Alíquota Zero" },
    { cst: "07", descricao: "07 - Operação Isenta da Contribuição" },
    { cst: "08", descricao: "08 - Operação sem Incidência da Contribuição" },
    { cst: "09", descricao: "09 - Operação com Suspensão da Contribuição" },
    { cst: "49", descricao: "49 - Outras Operações de Saída" }
  ];

  const [dialogModal, setDialogModal] = useState({open: false, keyword: "", title: "", description: "", buttons: [], result: ""});
  const handleCloseDialogModal = (result) => {setDialogModal({...dialogModal, keyword: "", open: false, result: result}); };


  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        //  ****************************
        //  ** Fetching Taxation Data **
        //  ****************************

        // ***---- Default Objects ----***
        const buildDefaultTaxation = (taxation) => ({
          csosn: taxation.cstCsosnIcms,
          cstPisCofins: taxation.cstPisCofins,
          ncm: taxation.ncm,
          cest: taxation.cest,
          tributacaoPadrao: true,
        });

        const nullTributacaoObject = {
          csosn: null,
          cstPisCofins: null,
          ncm: null,
          cest: null,
          tributacaoPadrao: null
        };

        // ***---- Fetching Empresa, Produto and Configurations Data ----***
        const { data: dataEmpresa } = await api.get("/v1/empresas");
        const empresaId = dataEmpresa.empresas[0].id;
        setEmpresaId(empresaId);

        // ***---- Fething Data ----***
        const { data: dataTributacaoEmpresa } = await api.get(`/v1/empresas-configuracoes-payload/${empresaId}`);
        const defaultTaxation = dataTributacaoEmpresa.tributacao;
        const defaultTaxationObject = defaultTaxation ? buildDefaultTaxation(defaultTaxation) : nullTributacaoObject;

        // ***---- Setting Default Taxation ----***
        setDefaultTaxation(defaultTaxationObject);

        //  ***************************
        //  ** Fetching Product Data **
        //  ***************************
        if (!produtoId) {

          const { data: dataProduto } = await api.get(`/v1/produtos/new/${empresaId}`);
          const tributacaoProduto = dataProduto.tributacao;
          
          // ***---- Setting Data ----***
          if (tributacaoProduto) initialState.tributacao = tributacaoProduto;
          else if (defaultTaxation) initialState.tributacao = defaultTaxationObject;
          else initialState.tributacao = nullTributacaoObject;

          setProduto(previousState => { return { ...previousState, ...initialState }; });
        }

        else {
          // ***---- API Calls ----***
          const  {data: dataProduto}  = await api.get(`/v1/produtos/${produtoId}`);

          // ***---- Data Produto ----***
          dataProduto.status = dataProduto.status === "Ativo";
          setProduto(previousState => { return { ...previousState, ...dataProduto }; });

          // ***---- Data Tributacao Empresa ----***
          const { data: dataTributacaoEmpresa } = await api.get(`/v1/empresas-configuracoes-payload/${empresaId}`);
          const empresaOperacao = dataTributacaoEmpresa?.tributacao?.operacao;
          setEmpresaProducaoPropria(["03", "04"].includes(empresaOperacao));
        }
      } catch (exception) {
        toastError(exception);
      }
    };

    open && fetchProduct();

    return () => setProduto(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, produtoId]);

  useEffect(() => {    
    const fetchEmbalagens = () => {
      api.get("/v1/embalagens")
        .then((value) => setEmbalagens(value.data))
        .catch((error) => toastError(error));
    };

    fetchEmbalagens(); 
  }, []);
  

  //  ***************
  //  ** Functions **
  //  ***************
  const handleChooseProdutoFoto = () => {
    const produtoFotoInput = document.getElementById("produtoFotoInput");
    produtoFotoInput.click();
  }

  const handleOpenCameraModal = () => { setCameraModalOpen(true); };
  const handleCloseCameraModal = () => { setCameraModalOpen(false); };
  const handleDeviceHasCameras = () => { handleOpenCameraModal(); };

  const handleOpenNcmSearchModal = () => { setNcmSearchModalOpen(true); };
  const handleCloseNcmSearchModal = () => { setNcmSearchModalOpen(false); };
  
  /*function getFileSizeString(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
      fileSizeInBytes /= 1024;
      i++;
    } while (fileSizeInBytes > 1024);
  
    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  }*/
  
  function isFileSize5Mb(fileSizeArchive) {
    if (fileSizeArchive > 5120000) {
      return true;
    }
  }

  const handleDrop = (e) => {
    const produtoFotoInput = document.getElementById("produtoFotoInput");
    const produtoFoto = document.getElementById("produtoFoto");
    const paperFotoImage = document.getElementById("paperFotoImage");
    const inputFotoImage = document.getElementById("inputFotoImage");
   
    const dT = new DataTransfer();

    produtoFotoInput.files = e.dataTransfer.files;    
    if (produtoFotoInput.files[0].type !== "image/png"      
      && produtoFotoInput.files[0].type !== "image/jpeg"
      && produtoFotoInput.files[0].type !== "image/jpg") {
        produtoFoto.value = "";
        produtoFotoInput.value = "";
        inputFotoImage.src = "";
        paperFotoImage.style.display = "none";
        
        setDialogModal({...dialogModal, open: true, title: "Seleção de Imagem.", description: "Informe imagens com extensões .jpeg .jpg .png", buttons: ["OK"]});        
        return;
    }    
    else{            
      if (isFileSize5Mb(produtoFotoInput.files[0].size)) {        
        setDialogModal({...dialogModal, open: true, title: "Seleção de Imagem.", 
                        description: "O tamanho da imagem é muito grande, Selecione imagens com 5Mb ou menores.",
                        buttons: ["OK"]});
                    
        produtoFoto.value = "";
        produtoFotoInput.value = "";
        inputFotoImage.src = "";
        paperFotoImage.style.display = "none";
        return;
      }
    }   

    dT.items.add(e.dataTransfer.files[0]);
    produtoFotoInput.files = dT.files;
    produtoFoto.value = produtoFotoInput.files[0].name;
    inputFotoImage.src = URL.createObjectURL(produtoFotoInput.files[0]);
    paperFotoImage.style.display = "block";

    e.preventDefault();
  }

  const handleRemoveLogoClick = () => {
    const produtoFotoInput = document.getElementById("produtoFotoInput");
    const produtoFoto = document.getElementById("produtoFoto");
    const inputFotoImage = document.getElementById("inputFotoImage");

    inputFotoImage.removeAttribute('src');
    produtoFoto.value = "";
    produtoFotoInput.value = "";
    inputFotoImage.src = "";

    setProduto({...produto, arquivoFoto: null});
  } 

  const handleChangeLogoClick = () => { document.getElementById("produtoFotoInput").click(); } 

  const handleChangeLogo = () => {
    const produtoFotoInput = document.getElementById("produtoFotoInput");
    const produtoFoto = document.getElementById("produtoFoto");
    const paperFotoImage = document.getElementById("paperFotoImage");
    const inputFotoImage = document.getElementById("inputFotoImage");
    
    if (produtoFotoInput.files[0].type !== "image/png"      
      && produtoFotoInput.files[0].type !== "image/jpeg"
      && produtoFotoInput.files[0].type !== "image/jpg") {
        produtoFoto.value = "";
        produtoFotoInput.value = "";
        inputFotoImage.src = "";
        paperFotoImage.style.display = "none";

        setDialogModal({...dialogModal, open: true, title: "Seleção de Imagem.", description: "Informe imagens com extensões .jpeg .jpg .png", buttons: ["OK"]});
        return;
    }
    else{
      if (isFileSize5Mb(produtoFotoInput.files[0].size)) {         
        setDialogModal({...dialogModal, open: true, title: "Seleção de Imagem.", 
                      description: "O tamanho da imagem é muito grande, Selecione imagens com 5Mb ou menores.",
                      buttons: ["OK"]});
        produtoFoto.value = "";
        produtoFotoInput.value = "";
        inputFotoImage.src = "";
        paperFotoImage.style.display = "none";
        return;
      }
    }
    
    produtoFoto.value = produtoFotoInput.files[0].name;
    inputFotoImage.src = URL.createObjectURL(produtoFotoInput.files[0]);
    paperFotoImage.style.display = "block";
  }

  const handleSaveProduto = async (values) => {
    if (!values.nome || !values.preco || !values.embalagemId) { 
      toast.error("Preecha os campos obrigatórios.");
    }
    else
    {
      try {
        const produtoData = { ...values };
        delete produtoData.empresa;
        const obj = { ...produtoData, empresaId: empresaId };
        
        if (obj.nome) obj.nome = obj.nome.trim();
        if (obj.preco) obj.preco =  parseFloat(obj.preco.toString().replace(",", "."));
        obj.status = obj.status ? "Ativo" : "Inativo";


        const formData = new FormData();
        const produtoFotoInput = document.getElementById("produtoFotoInput");
        formData.set("JSON", JSON.stringify(obj));
        formData.append("file", produtoFotoInput.files[0]);
        
        let dataBaseProduto = {};
        if (produtoId) {
          const {data} = await api.put(`/v1/produtos/${produtoId}`, formData);
          dataBaseProduto = { ...data };
        } else {
          const { data } = await api.post(`/v1/produtos`, formData);
          dataBaseProduto = { ...data };
        }

        delete dataBaseProduto.createdAt;
        delete dataBaseProduto.updatedAt;
        delete dataBaseProduto.empresa;

        if (produtoId) {
          const index = produtos.findIndex((p) => p.id === dataBaseProduto.id);
          produtos[index] = dataBaseProduto;
        }
        else {
          produtos.push(dataBaseProduto);
        }
        
        sortFunction(produtos);
        onClose();
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleClose = () => {
    onClose();
    setProduto(initialState);
    setDefaultTaxation(null);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
    <DialogModal
                title={dialogModal.title}
                open={dialogModal.open}
                onClose={handleCloseDialogModal}  
                description={dialogModal.description}   
                buttons={dialogModal.buttons}      
                >                
            </DialogModal>
    <div onDrop={handleDrop}>
      <Dialog
        open={open}
        scroll="paper"
        fullWidth={true}
        fullScreen={isSmallScreen}
        maxWidth={'true'}
        maxHeight={true}
        PaperProps={!isSmallScreen ? { style: { maxHeight: "80vh", maxWidth: "1000px", }, } : undefined}
      >
        <Formik
          initialValues={produto}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveProduto(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values, setFieldValue, validateForm, setFieldTouched }) => (
            <Form>
              {isSmallScreen
                ? (
                  <MainHeaderFX
                    title={i18n.t("produto.title")}
                    leftContainerType={"modal"}
                    rightContainerType={"modal"}
                    handleCloseModal={handleClose}
                    handleSaveModal={async () => {
                      const validationErrors = await validateForm();
                      const flattenedValidationErrors = flattenFormikValidationErrors(validationErrors);
                      const fieldsWithErrors = Object.keys(flattenedValidationErrors);
                      
                      if (fieldsWithErrors.length === 0) {
                        handleSaveProduto(values);
                      }
                      else {
                        fieldsWithErrors.forEach(field => setFieldTouched(field, true, false));
                        toast.info(i18n.t("produto.toasts.requiredFieldsAreBlank"));
                      }
                    }}
                  />
                )
                : (<DialogTitle>{i18n.t("produto.title")}</DialogTitle>)}

              <DialogContent className="dialogContent" dividers>
                <Container className="produto-dataCompany">
                  <Container className= {isSmallScreen ? "produto-dadosSmall" : "produto-dados"}>
                    <div hidden="hidden">
                      <Paper >
                        <CameraModal
                          open={cameraModalOpen}
                          onClose={handleCloseCameraModal}
                          produtoId={produtoId}
                        />

                        <Field
                          as={TextField}
                          id="produtoFoto"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          name="arquivoFoto"
                          InputProps={{ readOnly: true, }}
                          onClick={handleChooseProdutoFoto}
                        />

                        <input
                          name="arquivoFoto"
                          margin="dense"
                          variant="outlined"
                          id="produtoFotoInput"
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={handleChangeLogo}
                        />
                      </Paper>
                    </div>

                    <Paper
                      id="paperFotoImage"
                      className="produto-image"
                      style ={{ marginLeft: isSmallScreen ? "calc(45% - 115px)" : "0px" }}
                    >
                      <center>
                        <div className="produto-image2" onClick={handleChangeLogoClick}>
                          <img 
                            id="inputFotoImage"
                            alt=""
                            className="produto-paper"
                            src={produto.urlFoto ? produto.urlFoto : null}
                          />
                        </div>

                        <IconButton
                          size="small"
                          variant="contained"
                          color="inherit"
                          onClick={handleDeviceHasCameras}
                        >
                          <CameraAltOutlinedIcon />
                        </IconButton>

                        <IconButton
                          style={{paddingLeft: "20px", paddingRight: "20px"}}
                          size="small"
                          variant="contained"
                          color="inherit"
                          onClick={handleChooseProdutoFoto}
                          >
                        <FolderOpenOutlinedIcon />
                        </IconButton>

                        <IconButton
                          size="small"
                          variant="contained"
                          color="inherit"
                          onClick={handleRemoveLogoClick}
                        >
                          <DeleteOutlinedIcon />
                        </IconButton>
                      </center>
                    </Paper>             

                    <div className="produto-info">
                      <FlexTextField
                        autoFocus={true}
                        label={i18n.t("produto.fields.nome")}
                        name="nome"
                        width="100%"
                        error={touched.nome && Boolean(errors.nome)}
                        helperText={touched.nome && errors.nome ? "" : undefined}
                      />

                      <FlexSelectField
                        label={i18n.t("produto.fields.embalagem")}
                        name={"embalagemId"}
                        arrayList={embalagens.embalagens}
                        propArray={"unidade"}
                        propArrayId={"id"}
                        width={"100%"}
                        error={touched.embalagemId && !!errors.embalagemId}
                        helperText={touched.embalagemId && !!errors.embalagemId ? "" : undefined}
                      />
                      
                      <FlexNumericField
                        label={i18n.t("produto.fields.preco")}
                        name={"preco"}
                        inputMode="numeric"
                        width={"25%"}
                        error={touched.preco && !!errors.preco}
                        helperText={touched.preco && !!errors.preco ? "" : undefined}                                  
                      />

                      <FormControlLabel
                        control={<Field as={Switch} color="primary" name="status" checked={values.status} />}
                        label={(values.status) ? i18n.t("produto.fields.statusAtivo") : i18n.t("produto.fields.statusInativo")}
                      />
                    </div>
                  </Container>

                  <br />

                  <b>{i18n.t("empresaConfig.tributacaoPadrao.title")}</b>

                  <div className="produto-info"> 
                    <Container className="infoTributacao">
                      <div className="produto-info"> 
                        <FormControlLabel
                          control={<Field as={Switch} color="primary" name="tributacao.tributacaoPadrao" checked={values.tributacao.tributacaoPadrao} />}
                          label = {i18n.t("empresaConfig.tributacaoPadrao.padrao")}
                          onChange={(event) => {
                            const isTributacaoPadraoChecked = event.target.checked;
                            setFieldValue("tributacao.tributacaoPadrao", isTributacaoPadraoChecked);

                            if (isTributacaoPadraoChecked) {
                              setFieldValue("tributacao.csosn", defaultTaxation.csosn);
                              setFieldValue("tributacao.cstPisCofins", defaultTaxation.cstPisCofins);
                              setFieldValue("tributacao.ncm", defaultTaxation.ncm);
                              setFieldValue("tributacao.cest", defaultTaxation.cest);
                            }
                          }}
                        />
                      </div>

                      <div className="infoTributacao" style= {{ display: !values.tributacao.tributacaoPadrao ? "flex" : "none" }}>
                        {empresaProducaoPropria && (
                          <div className="produto-tributacao-linha">
                            <FlexSelectField
                              label={i18n.t("empresaConfig.tributacaoPadrao.cstCsosnIcms")}
                              name="tributacao.csosn"
                              arrayList={arrayCsosnProducaoPropria}
                              error={touched.tributacao && !!errors.tributacao?.csosn}
                              helperText={touched.tributacao && errors.tributacao?.csosn ? "" : undefined}
                              propArray={"descricao"}
                              propArrayId={"csosn"}
                              width="100%"
                            />
                          </div>
                        )}

                        {!empresaProducaoPropria && (
                          <div className="produto-tributacao-linha">
                            <FlexSelectField
                              label={i18n.t("empresaConfig.tributacaoPadrao.cstCsosnIcms")}
                              name="tributacao.csosn"
                              arrayList={arrayCsosn}
                              error={touched.tributacao && !!errors.tributacao?.csosn}
                              helperText={touched.tributacao && errors.tributacao?.csosn ? "" : undefined}
                              propArray={"descricao"}
                              propArrayId={"csosn"}
                              width="100%"
                            />
                          </div>
                        )}

                        <div className="produto-tributacao-linha">
                          <FlexSelectField
                            label={i18n.t("empresaConfig.tributacaoPadrao.cstPisCofins")}
                            name="tributacao.cstPisCofins"
                            arrayList={arrayPisCofins}
                            error={touched.tributacao && !!errors.tributacao?.cstPisCofins}
                            helperText={touched.tributacao && errors.tributacao?.cstPisCofins ? "" : undefined}
                            propArray={"descricao"}
                            propArrayId={"cst"}
                            width="73%"
                            formControlMarginTop="8px"
                          />

                          <div className="produto-tributacao-sublinha">
                            <FlexFormatField
                              label={i18n.t("empresaConfig.tributacaoPadrao.ncm")}
                              name="tributacao.ncm"
                              error={touched.tributacao && !!errors.tributacao?.ncm}
                              helperText={touched.tributacao && errors.tributacao?.ncm ? "" : undefined}
                              width="95%"
                              inputMode="numeric"
                              format="####.##.##"
                            />

                            <Button
                              className={"produto-tributacao-botao"}
                              onClick={handleOpenNcmSearchModal}
                              color="primary"
                              disabled={isSubmitting}
                              variant="contained"
                            >
                              <SearchOutlinedIcon />
                            </Button>
                          </div>

                          <NcmSearchModal
                            open={ncmSearchModalOpen}
                            onClose={handleCloseNcmSearchModal}
                            typedNcm={!values.tributacao.ncm ? "" : values.tributacao.ncm.toLowerCase()}
                          />
                        </div>

                        {values.tributacao.csosn === "500" && (
                          <div className="produto-tributacao-linha">
                            <FlexFormatField
                              label={i18n.t("empresaConfig.tributacaoPadrao.cest")}
                              name="tributacao.cest"
                              width="25%"
                              inputMode="numeric"
                              format="##.###.##"
                            />
                          </div>
                        )}
                      </div>
                    </Container>
                  </div>
                </Container>
              </DialogContent>

              {!isSmallScreen && (
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    color="inherit"
                    disabled={isSubmitting}
                    variant="outlined"
                  >
                    {i18n.t("button.cancel")}
                  </Button>

                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                  >
                    {produtoId
                      ? `${i18n.t("button.save")}`
                      : `${i18n.t("button.add")}`}
                    {isSubmitting && <CircularProgress size={24} />}
                  </Button>
                </DialogActions>
              )}
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  </>
  );
};

export default ProdutoModal;
