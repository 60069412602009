import React, { useEffect, useReducer, useState } from "react";
import {
  Container,
  Paper,
  SpeedDial,
  SpeedDialIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";

import naoEncontrado from "../../assets/naoEncontrado.svg"

import api from "../../services/api.js";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError.js";
import ClienteModal from "../../components/ClienteModal/index.js";
import MainHeaderFx from "../../components/MainHeaderFx/index.js";
import { Configuracao } from "../../configuracao/index.js";
import MainContainer from "../../components/MainContainer/index.js";
import TableRowSkeleton from "../../components/TableRowSkeleton/index.js";

import "./style.css";
import { formatarCnpjCpf } from "../../utils/formatacao/cnpjCpf.js";

const reducer = (state, action) => {
  if (action.type === "LOAD_CLIENTE") {
    const clientes = action.payload;
    const newClientes = [];

    clientes.forEach((cliente) => {
      const clienteIndex = state.findIndex((p) => p.id === cliente.id);
      if (clienteIndex !== -1) {
        state[clienteIndex] = cliente;
      } else {
        newClientes.push(cliente);
      }
    });

    return [...state, ...newClientes];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Cliente = () => {
  //  ***************
  //  ** Variáveis **
  //  ***************
  const [clientes, dispatch] = useReducer(reducer, []);
  const isSmallScreen = useMediaQuery("(max-width:825px)");

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const [search, setSearch] = useState("");

  const [clienteModalOpen, setClienteModalOpen] = useState(false);
  const [selectedCliente, setSelectedCliente] = useState(null);

  //  *****************
  //  ** Use Effects **
  //  *****************
  
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPage(1);
  }, [search]);

  useEffect(() => {
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchClientes = async () => {
        try {
          const { data } = await api.get("v1/clientes/", {
            params: { page, search, size: 20 },
          });
          setIsScroll(true);
          dispatch({ type: "LOAD_CLIENTE", payload: data.clientes });
          setHasMore(data.hasMore);
          setTotalCount(data.count);
          setLoading(false);
        } catch (error) {
          toastError(error);
        }
      };
      fetchClientes();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [page, search]);

  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenModal = () => {
    setClienteModalOpen(true);
  };

  const handleEditModal = (cliente) => {
    setSelectedCliente(cliente);
    setClienteModalOpen(true);
  };

  const handleCloseModal = () => {
    setClienteModalOpen(false);
    setSelectedCliente(null);
  };
  const loadMore = () => {
    if (isScroll) {
      setPage((previousState) => previousState + 1);
      setIsScroll(false);
    }
  };

  const handleScroll = (event) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollHeight - (scrollTop + 1) < clientHeight) {
      loadMore();
    }
  };

  //  ************
  //  ** Return **
  //  ************
  return (
    <div className="pageRoot">
      <ClienteModal
        open={clienteModalOpen}
        onClose={handleCloseModal}
        clienteId={selectedCliente && selectedCliente.id}
        clientes={clientes}
        setTotalCount={setTotalCount} 
      />

      <MainHeaderFx
        title={i18n.t("cliente.pluralTitle")}
        leftContainerType={"page"}
        rightContainerType={"search"}
        search={search}
        setSearch={setSearch}
        titleButton={i18n.t("button.add")}
        backParam={"/"}
        handleModal={handleOpenModal}
      />

      <MainContainer>
        <Paper className="scroll" onScroll={handleScroll}>
          <TableContainer>
            <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left"><strong>{i18n.t("cliente.table.nome")}</strong></TableCell>
                <TableCell align="left"><strong>{i18n.t("cliente.table.inscricao")}</strong></TableCell> 
              </TableRow>
            </TableHead>
              <TableBody>
                {clientes.map((cliente, index) => (
                  <TableRow
                    key={index}
                    onClick={() => handleEditModal(cliente)}
                  >
                    <TableCell> {cliente.nome} </TableCell>                    
                    <TableCell>{formatarCnpjCpf(cliente.inscricao)}</TableCell>
                  </TableRow>
                ))}
                {loading && <TableRowSkeleton columns={2} />}
              </TableBody>
            </Table>
          </TableContainer>

          {totalCount === 0 && !loading && (
            <Container class="record">
              <img alt="consulta" 
                id="logoTipo"                         
                style={{ width: "70%", height: "30%" }} 
                src={naoEncontrado}
              /> 
              <br/> 
              <span style={{fontSize: "1em", fontWeight:"bold", opacity: 0.85}} >{Configuracao.LISTAGEM_VAZIA} </span>
            </Container>
          )}

          {isSmallScreen && (
            <SpeedDial class="iconAdd" 
              ariaLabel="Add Product"
              onClick={handleOpenModal}
              open={false}
              icon={<SpeedDialIcon />}
            />            
          )}
        </Paper>
      </MainContainer>
    </div>
  );
};

export default Cliente;
