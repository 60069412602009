import React, { useEffect } from "react";

import { Button,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	IconButton,
	Input,
	Slider,
	TextField,
} from "@mui/material";  

import "./style.css";
import { Box } from "@mui/system";
import InformarCliente from "../InformarCliente";
import { geraJsonNota } from "../../utils/funcoes/geraJsonNota/geraJsonNota";
import DialogModal from "../DialogModal";
import { toast } from "react-toastify";
import api from "../../services/api";
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import LoadingModal from "../Modal/LoadingModal";

const DevolucaoItensModal = ({ isSmallScreen, nota, open, onClose, onAtualizar }) => {

	const [valorItens, setValorItens] = React.useState([]);
	const [valorTotalNota, setValorTotalNota] = React.useState(0);
	const [informarCliente, setInformarCliente] =  React.useState(false);
	const [cliente, setCliente] =  React.useState(null); 
	const [dialogModal, setDialogModal] = React.useState({open: false, keyword: "", title: "", description: "", buttons: [], result: ""});
	const [loadingScreen, setLoadingScreen] = React.useState(false);

	useEffect(() => {
		const atualizarNota = async () => {
			if (nota) {
				let valorItensList = [];
				for (const item of nota?.det) {
					valorItensList = [...valorItensList, item.prod.qCom]
				}
				setValorItens([...valorItensList]);
				setValorTotalNota(nota.icmsTot.vNF);
			}
			
		};
		atualizarNota();	
	}, [nota]);

	const handleChange = (index, value, maxValue) => {
		setValorItens((prev) => {	
            prev[index] = value > maxValue ? maxValue : (value < 0 ? 0 : value);
			return [...prev];
        });
    };

	const handleAlterarQuantidade = (index, value, maxValue) => {
		setValorItens((prev) => {	
			const valor = prev[index] + value;
            prev[index] = valor > maxValue ? maxValue : (valor < 0 ? 0 : valor);
			return [...prev];
        });
    };

	const handleZerarProdutos = () => {
		setValorItens((prev) => {	
			prev.forEach((_, index) => {
				prev[index] = 0;
			})
			return [...prev];
        });	
	}

	useEffect(() => {
		let valorTotal = 0;
		valorItens.forEach((item, index) => {
			valorTotal += item * nota?.det[index].prod.vUnCom;
		})	
		setValorTotalNota(valorTotal);
	}, [valorItens])

	useEffect(() => {
		if (cliente && cliente?.id > 0) {      
			prosseguirDevolucaoItens();    
		}
	  }, [cliente])

	const handleCloseModalInformarCliente = (clienteValue) => {    
		setInformarCliente(false);  
		setCliente(clienteValue ? clienteValue : null);
	}

	const confirmarDevolucaoItens = () => {
		if (nota) {
			if (nota?.ide?.mod === "65") {
				setInformarCliente(true);
			} else {
				prosseguirDevolucaoItens();
			}
				
		}
	}

	const prosseguirDevolucaoItens = async () => {	

		if (valorTotalNota <= 0) {
			setDialogModal({...dialogModal, keyword: "info", 
				open: true, 
				title: "Não foi possivel realizar a devolução!", 
				description: "O valor total não pode ser zero.", 
				buttons: ["OK"]});
		} else {
			try {
				setLoadingScreen(true);
	
				let notaDevolucao = {...nota, dest: nota?.ide?.mod === "65" && cliente ? {clienteId: cliente.id} : nota.dest};
				valorItens.forEach((item, index) => {
					notaDevolucao.det[index].prod.qCom = item;
				});	
				const jsonNota = await geraJsonNota(notaDevolucao, 'DEVOLVER')
	
				if (jsonNota.status === 200) {
					const envio = await api.post(`/v1/notas/`, jsonNota.nota);
					
					if (envio.data.cStat === '100') {
						toast.success(`Devolução realizada com sucesso! Chave: ${envio.data.chave}`);
					} else {                        
						setDialogModal({...dialogModal, keyword: "info", 
										open: true, 
										title: "Não foi possivel realizar a devolução!", 
										description: `${envio.data.mensagem}`, 
										buttons: ["OK"]});
					} 
					onAtualizar(envio.data)
				} 
				else {
					setDialogModal({...dialogModal, keyword: "info", 
									open: true, 
									title: "Não foi possivel realizar a devolução!", 
									description: `${jsonNota?.message}`, 
									buttons: ["OK"]});
				}
			} catch (error) { 			
				const errorMsg = error.response?.data?.message || error.response.data.error;       
				setDialogModal({...dialogModal, keyword: "info", 
								open: true, 
								title: "Não foi possivel realizar a devolução!", 
								description: `${errorMsg}`, 
								buttons: ["OK"]});
			}
			setLoadingScreen(false);	
			onClose();	
		}
		
	}

	const handleCloseDialogModal = (result) => {
		setDialogModal({...dialogModal, open: false, result: result});
	};

	const handleCloseLoadingModal = () => {    
		setLoadingScreen(false);
	};

	return (
		<>
			<LoadingModal
				open={loadingScreen}
				onClose={handleCloseLoadingModal}
			/>  
			<InformarCliente
				open={informarCliente}
				onClose={handleCloseModalInformarCliente}
			/> 
			<DialogModal
				title={dialogModal.title}
				open={dialogModal.open}
				onClose={handleCloseDialogModal}  
				description={dialogModal.description}   
				buttons={dialogModal.buttons}      
				>				
			</DialogModal>
			<Dialog
				open={open}
				onClose={() => onClose(false)}
				fullscreen={true}
			>
				<DialogContent dividers style={{backgroundColor: "white", fontSize: isSmallScreen ? "10px" : ""}}>
					<div style={{border: "solid 1px black", padding: "30px", paddingTop: "5px"}}>
						<table width={"100%"}><th align={"center"}><span>ITENS</span></th></table>
						<table width={"100%"}>
						<tr align={"left"}>
							<th>#</th>
							<th>Cód.</th>
							<th>Descrição</th>
							<th>Valor Unitário</th>
							<th>Qtd.</th>
						</tr>
						{nota?.det.map((array, index) => (
							<tr align={"right"}>
							<td>{index+1}</td>
							<td align={"center"}>{array.prod.cProd}</td>
							<td align={"left"}>{array.prod.xProd}</td>
							<td>{parseFloat(array.prod.vUnCom).toFixed(2).replace(".", ",")}</td>
							<td style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
								<IconButton>
                                    <IndeterminateCheckBoxIcon onClick={() => handleAlterarQuantidade(index, -1, Number(array.prod.qCom))}/>                                      
                                </IconButton>  
								<Box sx={{ width: 50 }}>
									<Grid container spacing={2} sx={{ alignItems: 'center' }}>
										<Grid item>
										<input
											style={{width: 50, height: 30}}
											type="number"
											value={valorItens[index]}
											min={0}
											max={array.prod.qCom}
											onChange={(e) => handleChange(index, Number(e.target.value), Number(array.prod.qCom))}
										/>
										</Grid>
									</Grid>
								</Box>
								<IconButton>
                                    <AddBoxIcon onClick={() => handleAlterarQuantidade(index, 1, Number(array.prod.qCom))}/>                                      
                                </IconButton>  
							</td>
							</tr>))}	
						</table>
						<div style={{display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "flex-end"}}>
							<span style={{color: 'var(--cor-primary)', cursor: "pointer", textDecoration:"underline"}} onClick={handleZerarProdutos}>Zerar</span></div>
						<table width={"100%"}>
							<tr><th align={"left"}><span><strong>VALOR TOTAL R$</strong></span></th><th align={"right"}> <span><strong>{parseFloat(valorTotalNota).toFixed(2).replace(".", ",")}</strong></span><br/></th></tr>
						</table>
						<hr /><br/>	
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => confirmarDevolucaoItens()}>{"Continuar"}</Button>
					<Button onClick={() => onClose(false)}>{"Cancelar"}</Button>
				</DialogActions>
			</Dialog>
		</>
		
	);
};

export default DevolucaoItensModal;
