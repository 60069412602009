import React, { useEffect, useReducer, useState } from "react";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery
} from "@mui/material";

import "./style.css"
import api from "../../services/api.js";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError.js";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MainHeaderFx from "../../components/MainHeaderFx";
import naoEncontrado from "../../assets/naoEncontrado.svg"
import { Configuracao } from "../../configuracao/index.js";
import MainContainer from "../../components/MainContainer/index.js";
import TableRowSkeleton from "../../components/TableRowSkeleton/index.js";
import VendasOptionsMenu from "../../components/VendasOptionsMenu/index.js";

const reducer = (state, action) => {
  if (action.type === "LOAD_NOTA") {
    const notas = action.payload;
    const newNotas = [];

    notas.forEach((nota) => {
      const notaIndex = state.findIndex((p) => p.id === nota.id);
      if (notaIndex !== -1) {
        state[notaIndex] = nota;
      } else {
        newNotas.push(nota);
      }
    });

    return [...state, ...newNotas];
  }

  if (action.type === "ATUALIZAR_NOTA") {
    const nota = action.payload;
    const notaIndex = state.findIndex((u) => u.id === nota.id);

    if (notaIndex !== -1) {
      state[notaIndex] = nota;
      return [...state];
    } else {
      return [nota, ...state];
    }
  }  

  if (action.type === "RESET") {
    return [];
  }
};

const Nota = () => {
  //  ***************
  //  ** Variáveis **
  //  ***************
  const [notas, dispatch] = useReducer(reducer, []);
  const [anchorEl, setAnchorEl] = useState(null);
  const isSmallScreen = useMediaQuery("(max-width:825px)");

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const [search, setSearch] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [notaAtual, setNotaAtual] = useState(null);
  const [clientX, setClientX] = useState(0);
  const [clientY, setClientY] = useState(0); 



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPage(1);
  }, [search]);

  useEffect(() => {
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchNotas = async () => {
        try {
          const { data } = await api.get("v1/notas/", {
            params: { search, page },
          });
          setIsScroll(true);
          dispatch({ type: "LOAD_NOTA", payload: data.notas });
          setHasMore(data.hasMore);
          setTotalCount(data.count);
          setLoading(false);
        } catch (error) {
          toastError(error);
        }
      };
      fetchNotas();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [page, search]);  



  //  ***************
  //  ** Functions **
  //  ***************
  const handleAtualizar = (venda) => {
    dispatch({ type: "ATUALIZAR_NOTA", payload: venda });
  }

  const loadMore = () => {
    if (isScroll) {
      setPage((previousState) => previousState + 1);
      setIsScroll(false);
    }
  };

  const handleScroll = (event) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollHeight - (scrollTop + 1) < clientHeight) loadMore();
  };

  const handleOpenVendasOptionsMenu = (e, nota) => {
		if (!nota || nota === undefined) return;

    setClientX(e.clientX);
    setClientY(e.clientY);
		setAnchorEl(e.currentTarget);
    setNotaAtual(nota);
    setMenuOpen(true);
	};

	const handleCloseVendasOptionsMenu = (nota) => {
    if (nota) {
      let notaIndex = notas.findIndex((notaItem) => notaItem?.id === notaAtual?.id);
			notas[notaIndex] = notaAtual;

      setIsScroll(true);          
      dispatch({ type: "LOAD_NOTA", payload: notas });
    }

		setAnchorEl(null);
    setMenuOpen(false);
    setClientX(0);
    setClientY(0);
	}

  function convertDate(dateString) {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const lastTwoDigits = year % 100;
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');  
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${day}/${month}/${lastTwoDigits} ${hours}:${minutes}`;
  }
  


  //  ************
  //  ** Return **
  //  ************
  return (
    <div className="pageRoot">
      <MainHeaderFx
        title="Notas"
        leftContainerType={"page"}
        rightContainerType={"search"}
        search={search}
        setSearch={setSearch}
        titleButton={i18n.t("button.add")}
        backParam={"/"}
        useHandleModal={false}
      />

      <MainContainer>  
        <Paper className="scroll" onScroll={handleScroll}>
          <TableContainer style={{overflowX: "hidden"}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ maxWidth: "80px"}}>{i18n.t("vendas.table.status")}</TableCell>
                  {!isSmallScreen && <TableCell align="center" style={{ maxWidth: "80px" }}>{i18n.t("vendas.table.type")}</TableCell>}
                  <TableCell align="center" style={{ maxWidth: "80px"}}>{!isSmallScreen && i18n.t("vendas.table.modelo")} {isSmallScreen && i18n.t("vendas.table.numeroSmartphone")}</TableCell>
                  <TableCell align="center" style={{ maxWidth: "60px"}}>{i18n.t("vendas.table.emissao")}</TableCell>
                  {!isSmallScreen && <TableCell align="center">{i18n.t("vendas.table.numero")}</TableCell>}
                  <TableCell align="right" style={{ maxWidth: "75px"}}>{i18n.t("vendas.table.valor")}</TableCell>
                  {!isSmallScreen && <TableCell align="center">{i18n.t("vendas.table.chave")}</TableCell>}
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {notas.map((nota, index) => (
                  <TableRow key={index} onClick={e => {handleOpenVendasOptionsMenu(e, nota)}}>
                    <TableCell style={{ maxWidth: isSmallScreen ? "80px" : "100px", textAlign: "center" }} align="center">
                      {nota.cStat === "100" && 
                      <div align="center">
                        {isSmallScreen && 
                        <div style={{minWidth: "70px", width: "70px", fontSize: "10px"}} 
                             className="status-autorizada">
                          <label className="label-status"> {nota.situacao}</label>
                        </div>}
                        {!isSmallScreen && 
                        <div style={{minWidth: "90px", width: "90px"}} 
                             className="status-autorizada">
                          <label className="label-status">{nota.situacao}</label>
                        </div>}
                      </div>}
                      {nota.cStat !== "100" && 
                      <div align="center">
                        {isSmallScreen && 
                        <div style={{minWidth: "70px", width: "70px", fontSize: "10px"}} 
                             className={nota.situacao === "Cancelada" ? "status-cancelada" : nota.situacao === "Inutilizada" ? "status-inutilizada" : "status-pendente"}>
                          <label className="label-status">{nota.situacao}</label>
                        </div>}
                        {!isSmallScreen && 
                        <div style={{minWidth: "90px", width: "90px"}} 
                             className={nota.situacao === "Cancelada" ? "status-cancelada" : nota.situacao === "Inutilizada" ? "status-inutilizada" : "status-pendente"}>
                          <label className="label-status">{nota.situacao}</label>
                        </div>}
                      </div>}
                    </TableCell>

                    {!isSmallScreen && (
                      <TableCell align="center">
                        {nota.ide.finNFe === "1" && <span>{i18n.t("vendas.table.types.normal")}</span>}
                        {nota.ide.finNFe === "2" && <span>{i18n.t("vendas.table.types.supplement")}</span>}
                        {nota.ide.finNFe === "3" && <span>{i18n.t("vendas.table.types.adjustment")}</span>}
                        {nota.ide.finNFe === "4" && <span>{i18n.t("vendas.table.types.return")}</span>}
                      </TableCell>
                    )}

                    {isSmallScreen && <TableCell align="center"><div>{nota.ide.mod}<br/>{nota.ide.nNF}</div></TableCell>}

                    {!isSmallScreen && <TableCell align="center" style={{ maxWidth: "80px"}}>{nota.ide.mod}</TableCell>}

                    <TableCell align="center" style={{ maxWidth: "80px"}}>{convertDate(nota.ide.dhEmi ? nota.ide.dhEmi : nota.createdAt)}</TableCell>

                    {!isSmallScreen && <TableCell align="center">{nota.ide.nNF}</TableCell>}

                    <TableCell align="right" style={{ maxWidth: "75px"}}>{parseFloat(nota.icmsTot.vNF).toFixed(2).replace(".", ",")}</TableCell>                    

                    {!isSmallScreen && <TableCell align="center">{nota.chave}</TableCell>}
                    
                    <TableCell align="left" style={{ maxWidth: "45px", textAlign: "center" }}>
                      <MoreVertIcon
                        onClick={e => {handleOpenVendasOptionsMenu(e, nota)}}
                        style={{ color: Configuracao.COR_PRIMARY }} 
                      />                   
                    </TableCell>
                  </TableRow>
                ))}

                {loading && <TableRowSkeleton columns={isSmallScreen ? 5 : 8} isSmallScreen={isSmallScreen} />}
              </TableBody> 
            </Table>
          </TableContainer>

          {totalCount === 0 && !loading && (
            <Container className="vendas-record">
              <img alt="consulta" 
                id="logoTipo"
                style={{ width: "70%", height: "30%" }} 
                src={naoEncontrado}
              /> 
              <br/> 
              <span style={{fontSize: "1em", fontWeight:"bold", opacity: 0.85}} >{Configuracao.LISTAGEM_VAZIA} </span>
            </Container>
          )}

          <VendasOptionsMenu
            nota={notaAtual}
            anchorEl={anchorEl}
            menuOpen={menuOpen}
            isSmallScreen={isSmallScreen}
            clientX={clientX}
            clientY={clientY}
            onAtualizar={handleAtualizar}
            handleClose={handleCloseVendasOptionsMenu}
          />
        </Paper>
      </MainContainer>
    </div>
  );
};

export default Nota;
