import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import { toast } from "react-toastify";
import ClearIcon from '@mui/icons-material/Clear';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import {
  Alert,
	Dialog,
	DialogContent,
	DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	videoContainer: { width: "95%", height: "80%", },

  displayedElement: { display: "block", },

  notDisplayedElement: { display: "none", },
  
  iconButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  
  floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)', },
  }
}));

const CameraModal = ({ open, onClose, produtoId }) => {
  // ***---- Datas ----***
	const classes = useStyles();
  const [webcamList, setWebcamList] = useState([]);
  const [webcam, setWebcam] = useState(null);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isCameraSnapped, setIsCameraSnapped] = useState(false);
  const [snapedPhoto, setSnapedPhoto] = useState(null);

  const videoConstraints = {
    width: 1920,
    height: 1080,
    facingMode: "environment"
  };

  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
    () => {
      const imgSrc = webcamRef.current.getScreenshot();
      
      const imageFile = base64ImageToFile(imgSrc, "snap.png")

      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(imageFile);
      setSnapedPhoto(dataTransfer);

      handleTurnOffCamera();
      setIsCameraSnapped(true);

      const fileInput = document.getElementById("produtoFotoInput");
      fileInput.files = dataTransfer.files;
      const inputFotoImage = document.getElementById("inputFotoImage");
      inputFotoImage.src = URL.createObjectURL(fileInput.files[0]);
      confirmSnap(dataTransfer);
      
    },
    [webcamRef]
  );

  // ***---- Use Effects ----***
  useEffect(() => {
    if (!open) return;

    const getVideoInputs = (mediaDevicesArray) => {
      const videoDevicesArray = mediaDevicesArray.filter(mediaDevice => mediaDevice.kind === "videoinput");
      setWebcamList(videoDevicesArray);
    };

    navigator.mediaDevices.enumerateDevices()
      .then(getVideoInputs)
      .catch(exception => {
        toast.info(i18n.t("cameraModal.toasts.exceptionListingDevices"));
        console.log("Exception caught when trying to list video devices:", exception);
      });
  }, [open]);
  

  // ***---- Functions ----***
	const handleClose = () => {
    webcam && webcam.stop();
    setIsCameraOn(false);
    setIsCameraSnapped(false);
    setSnapedPhoto(null);
		onClose();
	};

  const handleTurnOnCamera = () => {
    const webcamElement = document.getElementById("video");
    const canvasElement = document.getElementById("canvas");
    const webcamAPI = new Webcam(webcamElement, 'environment', canvasElement);

    webcamAPI.stream()
      .then(() => { setWebcam(webcamAPI); })
      .catch(err => { console.log("WebCam Error:", err); });

    setIsCameraSnapped(false);
    setIsCameraOn(true);
  };

  const handleTurnOffCamera = () => { 
    setIsCameraOn(false);
    setIsCameraSnapped(false);
  };

  const base64ImageToFile = (base64Image, filename) => {
    const base64ImageArray = base64Image.split(',');
  
    const mimetype = base64ImageArray[0].match(/:(.*?);/)[1];
  
    const decodedBase64Image = atob(base64ImageArray[1]);
    let decodedBase64ImageLength = decodedBase64Image.length;
  
    const imageArray = new Uint8Array(decodedBase64ImageLength);
    
    while (decodedBase64ImageLength--) {
      imageArray[decodedBase64ImageLength] = decodedBase64Image.charCodeAt(decodedBase64ImageLength);
    }
    
    return new File([imageArray], filename, { type: mimetype });
  }

  const handleSnapCamera = () => {
    const base64Image = webcam.snap();
    const imageFile = base64ImageToFile(base64Image, "snap.png")

    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(imageFile);
    setSnapedPhoto(dataTransfer);

    handleTurnOffCamera();
    setIsCameraSnapped(true);
  }

  const confirmSnap = (dataTransfer) => {
    const fileInput = document.getElementById("produtoFotoInput");
    fileInput.files = dataTransfer.files;
    const inputFotoImage = document.getElementById("inputFotoImage");
    inputFotoImage.src = URL.createObjectURL(fileInput.files[0]);

    handleClose();
  }

  function handleDataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }


  // ***---- Return ----***
	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					Câmera
				</DialogTitle>

        <DialogContent dividers>
          {webcamList.length === 0 && (
            <Alert severity="info">{i18n.t("cameraModal.messages.noVideoInputs")}</Alert>
          )}

          {webcamList.length > 0 && (
            <>
            <div>
            <Webcam
            className={isCameraSnapped 
              ? `${classes.videoContainer} ${classes.notDisplayedElement}` 
              : `${classes.videoContainer} ${classes.displayedElement}`
            }
              width="95%"
              height="80%"
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
            <canvas 
                id="canvas" 
                className={isCameraSnapped 
                  ? `${classes.videoContainer} ${classes.displayedElement}` 
                  : `${classes.videoContainer} ${classes.notDisplayedElement}`
                }
              >

              </canvas>
            </div>
            <div className={classes.iconButtonsContainer}>
              <Tooltip title="Tirar Foto" placement="top-start" arrow>
                <IconButton
                  color="inherit"
                  className={classes.floatingButton}
                  onClick={capture}
                  disabled={isCameraOn ? true : false}
                >
                  <CameraAltIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancelar" placement="top-start" arrow>
                <IconButton
                  color="inherit"
                  className={classes.floatingButton}
                  onClick={handleClose}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </div>
            </>
          )}
        </DialogContent>
			</Dialog>
		</div>
	);
};

export default CameraModal;
