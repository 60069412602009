import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Paper, Tooltip } from "@mui/material";
import { YoutubeSearchedForOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";

import { i18n } from "../../translate/i18n";
import { applyMask } from "../../utils/formatacao/applyMask";
import { dateTimeFormatter, numberFormatter } from "../../utils/intl/formatters";
import api from "../../services/api";
import MainContainer from "../../components/MainContainer";
import MainHeaderFx from "../../components/MainHeaderFx";
import toastError from "../../errors/toastError";

const RelatorioProdutos = () => {

  //  *****************
  //  **  Variables  **
  //  ***************** 
  const [empresaId, setEmpresaId] = useState(-1);
  const [isReportBeingGenerated, setIsReportBeingGenerated] = useState(false);
  const [reportObjectURL, setReportObjectURL] = useState(null);
  


  //  ******************
  //  **  Use Effects **
  //  ******************
  useEffect(() => {
    const fetchCompanyId = async () =>{
      try{
        const { data } = await api.get("/v1/empresas");
        setEmpresaId(data.empresas[0].id);
      } catch (exception) {
        toastError(exception);
        setEmpresaId(1);
      }
    }; 

    fetchCompanyId();
  }, []);



  //  ****************
  //  **  Function  **
  //  ****************
  const generateProductsReport = (companyData, productsDataSorted) => {
    //  ***---- Document Settings ----***
    //
    //  - p: portrait;
    //  - mm: millimiters;
    //  - a4: a4 format paper dimensions.
    //
    const document = new jsPDF("p", "mm", "a4");

    const pageWidth = document.internal.pageSize.width;
    const pageHeight = document.internal.pageSize.height;

    const creationDate = document.getCreationDate().split(":")[1].substring(0, 14);
    const footerCreationDateText = `${creationDate.substring(0, 4)}-${creationDate.substring(4, 6)}-${creationDate.substring(6, 8)} ${creationDate.substring(8, 10)}:${creationDate.substring(10, 12)}:${creationDate.substring(12)}`;

    // ***---- Functions ----***
    const writeTitle = (text, positionX, positionY, fontSize) => {
      document.setFillColor(240, 240, 240);
      document.rect(10, 2.75, pageWidth - 20, 10, "DF");
      document.setFontSize(fontSize);
      document.setFont("helvetica", "bold");
      document.text(text, positionX, positionY, { align:"center" });
    };

    const writeHeaderInfo = (text1, positionX1, positionY1, text2, positionX2, positionY2, fontSize) => {
      document.setFontSize(fontSize);
      document.setFont("helvetica", "bold");
      document.text(text1, positionX1, positionY1);
      document.setFont("helvetica", "normal");
      document.text(text2, positionX2, positionY2);
    };

    // ***----  Hearder, Footer and Hooks ----***
    const header = () => {
      writeTitle(i18n.t("productsReport.document.table.info.title"),pageWidth / 2, 10, 15);
      writeHeaderInfo(i18n.t("productsReport.document.table.info.company"), 10, 20, companyData.razaoSocial, 30, 20, 12);
      writeHeaderInfo(i18n.t("productsReport.document.table.info.stateRegistration"), 10, 27.50, companyData.inscricaoEstadual, 30, 27.50, 12);
      writeHeaderInfo(i18n.t("productsReport.document.table.info.cnpj"), pageWidth / 2, 27.50, applyMask(companyData.cnpj, "##.###.###/####-##", true), (pageWidth / 2) + 14, 27.50, 12);
      document.line(0, 35, pageWidth, 35);
    }

    const footer = (data) => {
      document.setFontSize(10);
      document.setFont("helvetica", "normal");
      document.text(`Nota Certa - ${dateTimeFormatter.format(new Date(footerCreationDateText))}`, data.settings.margin.left, pageHeight - 10);
    };
    
    const alignHeader = (data)  => {
      const isDataSectionHead = data.section === "head";

      if (isDataSectionHead && [0].includes(data.column.index)) data.cell.styles.halign = "left";
      else if (isDataSectionHead && [2,3,4,5,6,7].includes(data.column.index)) data.cell.styles.halign = "center";
      else if (isDataSectionHead && [1].includes(data.column.index)) data.cell.styles.halign = "right";
    };

    const highlightInactiveProducts = (data) => {
      data.row.section === "body"
      && data.column.dataKey === 2
      && data.cell.raw === "Inativo"
      && document.setTextColor(231, 76, 60);
    };

    const options = {
      willDrawPage: (data) => header()
      , didDrawPage: (data) => footer(data)
      , willDrawCell: (data) => {
        alignHeader(data);
        highlightInactiveProducts(data);
      }
      , alternateRowStyles: { fillColor: [245, 245, 245] }
      , margin: { top: 45, bottom: 20 }
      , columnStyles: { 0: { halign: "left" }, 1: { halign: "right" }, 2: { halign: "center" }, 3: { halign: "center" }, 4: { halign: "center" }, 5: { halign: "center" }, 6: { halign: "center" }, 7: { halign: "center" }}
      , showHead: "everyPage"
    }


    // ***---- Table Structure ----***
    const columns = [
      i18n.t("productsReport.document.table.header.name")
      , i18n.t("productsReport.document.table.header.value")
      , i18n.t("productsReport.document.table.header.status")
      , i18n.t("productsReport.document.table.header.package")
      , i18n.t("productsReport.document.table.header.csosn")
      , i18n.t("productsReport.document.table.header.cstPisCofins")
      , i18n.t("productsReport.document.table.header.ncm")
      , i18n.t("productsReport.document.table.header.cest")
    ];

    // ***---- PDF Structure ----***
    autoTable(document, {
      head: [columns]
      , body: productsDataSorted
      , startY: 40
      , ...options
    });


    // ***---- Exporting Repot----***
    const pdfBlob = document.output("blob");
    const pdfURL = URL.createObjectURL(pdfBlob);

    if (window.navigator.pdfViewerEnabled) setReportObjectURL(pdfURL);
    else window.open(pdfURL, "_blank");
  };

  const handleGenerateReport = async() =>{
    //  ***----Validations----*** 
    if (isReportBeingGenerated) {
      toast.info(i18n.t("productsReport.validations.tooManyRequisitions"))
      return;
    }
    
    //  ***---- Generating Report ----***
    try {
      setIsReportBeingGenerated(true);
      setReportObjectURL(null);
      
      const { data } = await api.get("/v1/productsReport", { params: { empresaId }, });
      
      if (data.type === "success"){
        const productsDataSorted = data.productsReportData.sort((productA, productB) => { 
          const productComparison = productA.nome.localeCompare(productB.nome);
          if (productComparison !== 0) return productComparison;
          return productA.embalagem.unidade.localeCompare(productB.embalagem.unidade);
        }).map(product => [
            product.nome
            ,  numberFormatter.format(parseFloat(product.preco))
            ,  product.status
            ,  product.embalagem.unidade
            ,  product.tributacao.csosn
            ,  product.tributacao.cstPisCofins
            ,  product.tributacao.ncm !== "" ? applyMask(product.tributacao.ncm, "####.##.##", true) : "-"
            ,  product.tributacao.cest !== "" ? applyMask(product.tributacao.cest, "##.###.##", true) : "-"
        ]);
        const isReportEmpty = !data.companyData || productsDataSorted.length === 0;

        if (isReportEmpty){
          toast.info(i18n.t("productsReport.toasts.emptyReport"))
          setIsReportBeingGenerated(false);
          return;
        }
        generateProductsReport(data.companyData, productsDataSorted);
      }
      
      setIsReportBeingGenerated(false);
    } catch (exception){
      setIsReportBeingGenerated(false);
      toast.error(exception);
    }

  }

  //  **************
  //  **  Return  **  
  //  **************
  return(
    <div className="pageRoot">
      <MainHeaderFx
        title={i18n.t("productsReport.title")}
        leftContainerType={"page"}
        rightContainerType={"hidden"}
        backParam={"/"}
      />

      <MainContainer>
        <Paper className="paperContainer">
          <div className="filterContainer">
            <Tooltip title={i18n.t("productsReport.filters.generateReportButton")} placement="top-start" arrow>
              <Button
                color="primary"
                variant="contained"
                className="generateReportButton"
                onClick={handleGenerateReport}
                disabled={isReportBeingGenerated}
              >
                <YoutubeSearchedForOutlined/>
              </Button>
            </Tooltip>
          </div>  

          <div className="reportContainer">
            {isReportBeingGenerated && (<CircularProgress size={24}/>)}

            {!isReportBeingGenerated && reportObjectURL && window.navigator.pdfViewerEnabled && (
              <object data={reportObjectURL} style={{width: "100%", height: "100%"}} type="application/pdf">
              </object>
            )}
          </div>
        </Paper>
      </MainContainer>
    </div>
  );
};
export default RelatorioProdutos;