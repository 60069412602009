import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  InputAdornment,
  TextField,
  Button,
  useMediaQuery,
} from "@mui/material";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import { i18n } from "../../translate/i18n";
import { Configuracao } from "../../configuracao";
import Title from "../Title";

import "./style.css";

/**
 *
 * @param string title
 * @param string{"page"|"modal"|"hidden"} leftContainerType
 * @param string{"search"|"modal"|"hidden"} rightContainerType
 * @param string search
 * @param function setSearch
 * @param string backParam
 * @param function handleCloseModal
 * @param function handleSaveModal
 * @returns
 */
const MainHeaderFX = ({
  title,
  titleButton,
  leftContainerType,
  rightContainerType,
  search,
  setSearch,
  backParam,
  handleBackModal,
  handleCloseModal,
  handleSaveModal,
  handleModal,
  useHandleModal = true,
  backModal = false
}) => {
  const navigate = useNavigate(false);
  const isSmallScreen = useMediaQuery("(max-width:825px)");
  const [rightContainerExpanded, setRightContainerExpanded] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const handleSearchFieldFocus = () => {
    if (!isSearching) {
      setRightContainerExpanded(true);
      const searchField = document.getElementById("searchField");
      searchField.focus();
      isSmallScreen && setIsSearching(true);
    }
  };

  const handleSearchFieldBlur = () => {
    setRightContainerExpanded(false);
    setIsSearching(false);
    setSearch("");
  };

  const handleOnChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleEscKeyDown = (event) => {
    if (event.key === "Escape") {
      handleSearchFieldBlur();
    }
  };

  const handleBack = () => {
    setTimeout(() => {
      if (typeof handleBackModal === "function") handleBackModal();
      else navigate(backParam);      
    }, 1);
  };

  return (
    <div className="mainContainerHeader">
      {leftContainerType === "page" && (
        <>
          {!isSearching && isSmallScreen &&(            
            <div className="leftHeaderContainer" onClick={handleBack}>              
              <IconButton>
                <ArrowBackIosOutlinedIcon
                  style={{
                    color: Configuracao.COR_SECUNDARY,
                    fontSize: 25,
                    marginLeft: "3px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </div>
          )}

          {!isSmallScreen && <div></div>}
        </>
      )}

      {leftContainerType === "modal" && (
        <div className="leftHeaderContainer" onClick={handleCloseModal}>
          <IconButton>            
            {backModal ? (
              <ArrowBackIosOutlinedIcon style={{color: Configuracao.COR_SECUNDARY, fontSize: 25, marginLeft: "3px",cursor: "pointer", }}/>
            ): (
              <CloseOutlinedIcon style={{color: Configuracao.COR_SECUNDARY, fontSize: 25, marginLeft: "3px",cursor: "pointer", }}/>
            )} 
              
          </IconButton>
        </div>
      )}

      {leftContainerType === "hidden" && <div></div>}

      {!isSearching && (
        <div className="titleHeaderContainer">
          <Title visibility={!isSearching}>{title}</Title>
        </div>
      )}

      {isSearching && <div></div>}

      {rightContainerType === "search" && (
        <div
          className="rigthHeaderButtonSeacher"
          style={{
            width: isSmallScreen
              ? rightContainerExpanded
                ? "100%"
                : "50px"
              : undefined,
          }}
        >
          <div
            className="rightHeaderContainer"
            style={{
              width: isSmallScreen
                ? rightContainerExpanded
                  ? "100%"
                  : "50px"
                : undefined,
              marginRight: "7px",
              transition: isSmallScreen ? "all 0.1s ease" : undefined,
            }}
          >
            <TextField
              id="searchField"
              style={{
                width: isSmallScreen
                  ? rightContainerExpanded
                    ? "100%"
                    : "50px"
                  : undefined,
              }}
              variant="outlined"
              placeholder={i18n.t("search.search")}
              autoComplete="off"
              focused={true}
              type="search"
              value={search}
              onFocus={handleSearchFieldFocus}
              onChange={(event) => handleOnChangeSearch(event)}
              onKeyDown={(event) => handleEscKeyDown(event)}
              InputProps={{
                style: { color: "white" },
                startAdornment: !isSearching && (
                  <InputAdornment
                    position="start"
                    onClick={handleSearchFieldFocus}
                  >
                    <IconButton>
                      <SearchOutlinedIcon
                        style={{
                          color: Configuracao.COR_6,
                          fontSize: 25,
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: isSearching && (
                  <InputAdornment
                    position="end"
                    onClick={handleSearchFieldBlur}
                  >
                    <IconButton edge="end">
                      <CloseOutlinedIcon
                        style={{ color: Configuracao.COR_6, fontSize: 25 }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          {!isSmallScreen && useHandleModal && (
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: "1em" }}
              onClick={handleModal}
            >
              {titleButton}
            </Button>
          )}
        </div>
      )}

      {rightContainerType === "modal" && (
        <div className="rightHeaderContainer" onClick={handleSaveModal}>
          <IconButton>
            <CheckOutlinedIcon
              style={{
                color: Configuracao.COR_SECUNDARY,
                fontSize: 25,
                marginRight: "3px",
                cursor: "pointer",
              }}
            />
          </IconButton>
        </div>
      )}

      {rightContainerType === "hidden" && <div></div>}
    </div>
  );
};

export default MainHeaderFX;
