import React from "react";
import { DatePicker } from "@mui/x-date-pickers";

const FlexDateInput = ({
  label
  , views = ["year", "month", "day"]
  , format = "DD/MM/YYYY"
  , defaultValue
  , handleValueChange
}) => {
  //  ************
  //  ** Return **
  //  ************
  return (
    <DatePicker
      className="dateInput"
      style={{ width: "210px" }}
      label={label}
      views={views}
      defaultValue={defaultValue}
      value={defaultValue}
      onChange={newValue => handleValueChange(newValue)}
      format={format}
    />
  );
};

export default FlexDateInput;
