import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, useMediaQuery } from "@mui/material";
import { Field, useFormikContext } from "formik";

const FlexSelectField = ({ 
	label, 
	name, 
	width, 
	arrayList, 
	propArray, 
	propArrayId, 
	applyNegativeMargin = false, 
	marginTop = "0px",
	formControlMarginTop = "0px",
	error, 
	helperText, 
	customOnChange}) => {

	const isSmallScreen = useMediaQuery('(max-width:825px)');
	const { handleChange } = useFormikContext();

	return (
		<FormControl 
			style={{
				width: isSmallScreen ? "100%" : width,
				display: "flex",
				flexWrap: "wrap",
				justifyContent: "end",
				marginBottom: "0px",
				marginTop: formControlMarginTop 
			}}
		>
			<InputLabel style={error ? {color: "rgb(211,47,47)"} : {}}>{label}</InputLabel>

			<Field 			
				as={Select}
				name={name}
				label={label}
				error={error}
				fullWidth
				
				style={{
					marginBottom: applyNegativeMargin ? "-4px" : "4px",
					marginTop: marginTop,
				}}

				onChange={(event) => {
					handleChange(event);
					if (customOnChange) customOnChange(event);
				}}
			>		

				{arrayList?.map(array => (
					<MenuItem
						key={array[propArrayId]}
						value={array[propArrayId]}
						style={{wordBreak: "break-word", textWrap: "wrap", marginBottom: "1px"}}	
					>
						{array[propArray]}
					</MenuItem>
				))}
			</Field>

			{error && <FormHelperText style={{position: "absolute", color: "rgb(211,47,47)", marginBottom: "-16px"}}>{helperText}</FormHelperText>}
		</FormControl>		
	);
};

export default FlexSelectField;