import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, CircularProgress, Paper, Tooltip } from "@mui/material";
import YoutubeSearchedForOutlinedIcon from '@mui/icons-material/YoutubeSearchedForOutlined';

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { jsPDF } from "jspdf";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import autoTable from "jspdf-autotable";
import dayjs from "dayjs";

import "dayjs/locale/pt";

import { applyMask } from "../../utils/formatacao/applyMask";
import { dateFormatter, dateTimeFormatter, numberFormatter } from "../../utils/intl/formatters";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import FlexDateInput from "../../components/input/Fields/FlexDateInput";
import MainContainer from "../../components/MainContainer";
import MainHeaderFx from "../../components/MainHeaderFx";
import toastError from "../../errors/toastError";

import "./style.css";

const RelatorioNotas = () => {
  //  ***************
  //  ** Variables **
  //  ***************  
  const [empresaId, setEmpresaId] = useState(-1);
  const [isReportBeingGenerated, setIsReportBeingGenerated] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));

  const [reportObjectURL, setReportObjectURL] = useState(null);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const fetchCompanyId = async () => {
      try {
        const { data } = await api.get("/v1/empresas");
        setEmpresaId(data.empresas[0].id);
      } catch (exception) {
        toastError(exception);
        setEmpresaId(1);
      }
    };

    fetchCompanyId();
  }, []);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleChangeSelectedDate = (newValue) => { setSelectedDate(newValue); };

  const generateSalesReport = (companyData, normalSalesData, returnedSalesData) => {
    // ***---- Document Settings ----***
    //
    //  - p: portrait;
    //  - mm: millimiters;
    //  - a4: a4 format paper dimensions.
    //
    const document = new jsPDF("p", "mm", "a4");

    const pageWidth = document.internal.pageSize.width;
    const pageHeight = document.internal.pageSize.height;

    const selectedDateObject = new Date(selectedDate);
    const headerPeriodMonth = (selectedDateObject.getMonth() + 1).toString().padStart(2, "0");
    const headerPeriodYear = selectedDateObject.getFullYear();

    const creationDate = document.getCreationDate().split(":")[1].substring(0, 14);
    const footerCreationDateText = `${creationDate.substring(0, 4)}-${creationDate.substring(4, 6)}-${creationDate.substring(6, 8)} ${creationDate.substring(8, 10)}:${creationDate.substring(10, 12)}:${creationDate.substring(12)}`;

    const normalSalesTotal = normalSalesData.reduce((accumulator, item) => accumulator + item.nota.icmsTot.vNF, 0);
    const normalSalesTotalCancelled = normalSalesData.filter(item => item.nota.situacao === "Cancelada").reduce((accumulator, item) => accumulator + item.nota.icmsTot.vNF, 0);
    const normalSalesTotalAuthorized = normalSalesData.filter(item => item.nota.situacao === "Autorizada").reduce((accumulator, item) => accumulator + item.nota.icmsTot.vNF, 0);

    const returnedSalesTotal = returnedSalesData.reduce((accumulator, item) => accumulator + item.nota.icmsTot.vNF, 0);
    const returnedSalesTotalCancelled = returnedSalesData.filter(item => item.nota.situacao === "Cancelada").reduce((accumulator, item) => accumulator + item.nota.icmsTot.vNF,0);
    const returnedSalesTotalAuthorized = returnedSalesData.filter(item => item.nota.situacao === "Autorizada").reduce((accumulator, item) => accumulator + item.nota.icmsTot.vNF, 0);


    // ***---- Functions ----***
    const writeTitle = (text, positionX, positionY, fontSize) => {
      document.setFillColor(240, 240, 240);
      document.rect(10, 2.75, pageWidth - 20, 10, "DF");
      document.setFontSize(fontSize);
      document.setFont("helvetica", "bold");
      document.text(text, positionX, positionY, { align: "center" });
    };

    const writeHeaderInfo = (text1, positionX1, positionY1, text2, positionX2, positionY2, fontSize) => {
      document.setFontSize(fontSize);
      document.setFont("helvetica", "bold");
      document.text(text1, positionX1, positionY1);
      document.setFont("helvetica", "normal");
      document.text(text2, positionX2, positionY2);
    };

    const writeBodyInfo = (text, positionX, positionY, fontSize) => {
      document.setFontSize(fontSize);
      document.setFont("helvetica", "bold");
      document.text(text, positionX, positionY);
    };



    // ***---- Header, Footer and Hooks ----***
    const header = () => {
      writeTitle(i18n.t("receiptsReport.document.table.info.title"), pageWidth / 2, 10, 15);
      writeHeaderInfo(i18n.t("receiptsReport.document.table.info.company"), 10, 20, companyData.razaoSocial, 30, 20, 12);
      writeHeaderInfo(i18n.t("receiptsReport.document.table.info.stateRegistration"), 10, 27.50, companyData.inscricaoEstadual, 30, 27.50, 12);
      writeHeaderInfo(i18n.t("receiptsReport.document.table.info.cnpj"), pageWidth / 2, 27.50, applyMask(companyData.cnpj, "##.###.###/####-##", true), (pageWidth / 2) + 14, 27.50, 12);
      writeHeaderInfo(i18n.t("receiptsReport.document.table.info.period"), 10, 35, `${headerPeriodMonth}/${headerPeriodYear}`, 28, 35, 12);
      document.line(0, 40, pageWidth, 40);
    };

    const footer = (data) => {
      document.setFontSize(10);
      document.setFont("helvetica", "normal");
      document.text(`Nota Certa - ${dateTimeFormatter.format(new Date(footerCreationDateText))}`, data.settings.margin.left, pageHeight - 10);
    };

    const alignHeader = (data) => {
      const isDataSectionHead = data.section === "head";

      if (isDataSectionHead && [0].includes(data.column.index)) data.cell.styles.halign = "left";
      else if (isDataSectionHead && [1, 3].includes(data.column.index)) data.cell.styles.halign = "center";
      else if (isDataSectionHead && [2, 4].includes(data.column.index)) data.cell.styles.halign = "right";
    };

    const highlightCancelledReceipts = (data) => {
      data.row.section === "body"
        && data.column.dataKey === 3
        && data.cell.raw === "Cancelada"
        && document.setTextColor(231, 76, 60);
    };

    const highlightFooter = (data) => {
      data.row.section === "foot" && document.setTextColor(0, 0 ,0);
    }

    const options = {
      willDrawPage: (data) => header()
      , didDrawPage: (data) => footer(data)
      , willDrawCell: (data) => {
        alignHeader(data);
        highlightCancelledReceipts(data);
        highlightFooter(data);
      }
      , alternateRowStyles: { fillColor: [245, 245, 245] }
      , margin: { top: 45, bottom: 20 }
      , columnStyles: { 0: { halign: "left" }, 1: { halign: "center" }, 2: { halign: "right" }, 3: { halign: "center" }, 4: { halign: "right" } }
      , footStyles: { halign: "right", fillColor: [255, 255, 255] }
      , showHead: "everyPage"
      , showFoot: "lastPage"
    };



    // ***---- Table Structure ----***
    const columns = [
      i18n.t("receiptsReport.document.table.header.date")
      , i18n.t("receiptsReport.document.table.header.model")
      , i18n.t("receiptsReport.document.table.header.number")
      , i18n.t("receiptsReport.document.table.header.status")
      , i18n.t("receiptsReport.document.table.header.total")
    ];

    const modMap = {
      "65": "NFC-e"
      , "55": "NF-e" 
    };

    const normalSalesRows = normalSalesData.map(normalSale => [
      dateFormatter.format(new Date(normalSale.dhEmi))
      , modMap[normalSale.mod] || normalSale.mod
      , normalSale.nNF
      , normalSale.nota.situacao
      , numberFormatter.format(parseFloat(normalSale.nota.icmsTot.vNF))
    ]);

    const returnedSalesRows = returnedSalesData.map(returnedSale => [
      dateFormatter.format(new Date(returnedSale.dhEmi))
      , modMap[returnedSale.mod] || returnedSale.mod
      , returnedSale.nNF
      , returnedSale.nota.situacao
      , numberFormatter.format(parseFloat(returnedSale.nota.icmsTot.vNF))
    ]);



    // ***---- PDF Structure ----***
    if (normalSalesRows.length > 0) {
      writeBodyInfo(i18n.t("receiptsReport.document.table.info.sales"), 15, 50, 12);
      
      autoTable(document, {
        head: [columns]
        , body: normalSalesRows
        , startY: 55
        , foot: [
          ["", "", "", i18n.t("receiptsReport.document.table.info.total"), numberFormatter.format(normalSalesTotal)]
          , ["", "", "", i18n.t("receiptsReport.document.table.info.cancelled"), numberFormatter.format(normalSalesTotalCancelled)]
          , ["", "", "", i18n.t("receiptsReport.document.table.info.authorized"), numberFormatter.format(normalSalesTotalAuthorized)]
        ]
        , ...options
      });
    }

    if (returnedSalesRows.length > 0) {
      writeBodyInfo(i18n.t("receiptsReport.document.table.info.returns"), 15, document.lastAutoTable.finalY + 10, 12);

      autoTable(document, {
        head: [columns]
        , body: returnedSalesRows
        , startY: document.lastAutoTable.finalY + 15
        , foot: [
          ["", "", "", i18n.t("receiptsReport.document.table.info.total"), numberFormatter.format(returnedSalesTotal)]  
          , ["", "", "", i18n.t("receiptsReport.document.table.info.cancelled"), numberFormatter.format(returnedSalesTotalCancelled)]
          , ["", "", "", i18n.t("receiptsReport.document.table.info.authorized") , numberFormatter.format(returnedSalesTotalAuthorized)]      
        ]
        , ...options
      });
    }



    // ***---- Exporting Report ----***
    const pdfBlob = document.output("blob");
    const pdfURL = URL.createObjectURL(pdfBlob);

    if (window.navigator.pdfViewerEnabled) setReportObjectURL(pdfURL);
    else window.open(pdfURL, "_blank");
  };

  const handleGenerateReport = async () => {
    // ***---- Variables ----***
    const selectedDateObject = new Date(selectedDate);
    const isSelectedDateInvalid = isNaN(selectedDateObject.getTime());

    // ***---- Validations ----***
    if (isReportBeingGenerated) {
      toast.info(i18n.t("receiptsReport.validations.tooManyRequisitions"));
      return;
    };

    if (isSelectedDateInvalid) {
      toast.info(i18n.t("receiptsReport.validations.invalidDate"));
      return;
    }

    // ***---- Generating Report ----***
    try {
      setIsReportBeingGenerated(true);
      setReportObjectURL(null);

      const { data } = await api.get("/v1/receiptsReport", { params: { empresaId, selectedDate }, });
      
      if (data.type === "warning") {
        toast.info(i18n.t(`receiptsReport.validations.${data.msg}`));
      }

      if (data.type === "success") {  
        const normalSalesData = data.receiptsReportData
          .filter(sale => ["1", "2", "3"].includes(sale.finNFe))
          .sort((saleA, saleB) => { 
            const dateComparison = new Date(saleA.dhEmi).setHours(0, 0, 0, 0) - new Date(saleB.dhEmi).setHours(0, 0, 0, 0);
            if (dateComparison !== 0) return dateComparison;
            return saleA.nNF - saleB.nNF;
          });

        const returnedSalesData = data.receiptsReportData
          .filter(sale => ["4"].includes(sale.finNFe))
          .sort((saleA, saleB) => { 
            const dateComparison = new Date(saleA.dhEmi).setHours(0, 0, 0, 0) - new Date(saleB.dhEmi).setHours(0, 0, 0, 0);
            if (dateComparison !== 0) return dateComparison;
            return saleA.nNF - saleB.nNF;
          });
          
        const isReportEmpty = !data.companyData || (normalSalesData.length === 0 && returnedSalesData.length === 0);

        if (isReportEmpty) {
          toast.info(i18n.t("receiptsReport.toasts.emptyReport"));
          setIsReportBeingGenerated(false);
          return;
        }

        generateSalesReport(data.companyData, normalSalesData, returnedSalesData);
      }

      setIsReportBeingGenerated(false);
    } catch (exception) {
      setIsReportBeingGenerated(false);
      toast.error(exception);
    }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className="pageRoot">
      <MainHeaderFx
        title={i18n.t("receiptsReport.title")}
        leftContainerType={"page"}
        rightContainerType={"hidden"}
        backParam={"/"}
      />

      <MainContainer>
        <Paper className="paperContainer">
          <div className="filterContainer">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt">
              <FlexDateInput
                label={i18n.t("receiptsReport.filters.date")}
                views={["year", "month"]}
                format={"MMMM/YYYY"}
                defaultValue={selectedDate}
                handleValueChange={newValue => handleChangeSelectedDate(newValue)}
              />
            </LocalizationProvider>

            <Tooltip title={i18n.t("receiptsReport.filters.generateReportButton")} placement="top-start" arrow>
              <Button
                color="primary"
                variant="contained"
                className="generateReportButton"
                onClick={handleGenerateReport}
                disabled={isReportBeingGenerated}
              >
                <YoutubeSearchedForOutlinedIcon />
              </Button>
            </Tooltip>
          </div>

          <div className="reportContainer">
            {isReportBeingGenerated && (<CircularProgress size={24} />)}

            {!isReportBeingGenerated && reportObjectURL && window.navigator.pdfViewerEnabled && (           
              <object data={reportObjectURL} style={{width: "100%", height: "100%"}} type="application/pdf">
              </object>
            )}
          </div>
        </Paper>
      </MainContainer>
    </div>
  );
};

export default RelatorioNotas;
