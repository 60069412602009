import React from "react";
import { TableCell, TableRow, Skeleton } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
	customTableCell: { borderRadius: "20px", display: "flex", alignItems: "center", justifyContent: "center", },
}));

const TableRowSkeleton = ({ avatar, columns, isSmallScreen = false }) => {
	const classes = useStyles();
	return (
		<>
			<TableRow>
				{avatar && (
					<>
						<TableCell style={{ paddingRight: 0 }}>
							<Skeleton animation="wave" variant="circle" width={40} height={40} />
						</TableCell>

						<TableCell>
							<Skeleton animation="wave" width={80} height={30} />
						</TableCell>
					</>
				)}

				{Array.from({ length: columns }, (_, index) => (
					<TableCell align="center" key={index}>
						<div className={classes.customTableCell}>
							<Skeleton
								align="center"
								animation="wave"
								width={isSmallScreen ? 50 : 80}
								height={30}
							/>
						</div>
					</TableCell>
				))}
			</TableRow>
		</>
	);
};

export default TableRowSkeleton;
