import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { i18n } from "../../translate/i18n";

const MenuRedirectorContext = createContext();

const MenuRedirectorProvider = ({ children }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const navigate = useNavigate();

  const [redirectorSelectedIndex, setRedirectorSelectedIndex] = useState(0);
  const [redirectorSelectedRoute, setRedirectorSelectedRoute] = useState("/");
  const [redirectorSettingsDialogModalInfo, setRedirectorSettingsDialogModalInfo] = useState({
    title: i18n.t("menuRedirectorContext.settingsDialogModalInfo.title")
    , description: i18n.t("menuRedirectorContext.settingsDialogModalInfo.description")
    , buttons: [
      i18n.t("menuRedirectorContext.settingsDialogModalInfo.buttons.save")
      , i18n.t("menuRedirectorContext.settingsDialogModalInfo.buttons.cancel")
    ]
    , open: false
  });



  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenRedirectorSettingsDialogModal = () => {
    setRedirectorSettingsDialogModalInfo(previousValue => { return { ...previousValue, open: true }; });
  };

  const handleCloseRedirectorSettingsDialogModal = (result) => {
    setRedirectorSettingsDialogModalInfo(previousValue => { return { ...previousValue, open: false }; } );

    if (result === "Salvar") document.getElementById("empresaConfig_save").click();
    setTimeout(() => {
      if (redirectorSelectedIndex === 3) navigate("/?emitirNfce=1");
      else navigate(`/${redirectorSelectedRoute}`);
    }, 1);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <MenuRedirectorContext.Provider value={{
      redirectorSelectedIndex,
      redirectorSelectedRoute,
      redirectorSettingsDialogModalInfo,

      setRedirectorSelectedIndex,
      setRedirectorSelectedRoute,
      setRedirectorSettingsDialogModalInfo,

      handleOpenRedirectorSettingsDialogModal,
      handleCloseRedirectorSettingsDialogModal,
    }}>
      {children}
    </MenuRedirectorContext.Provider>
  );
};

export { MenuRedirectorContext, MenuRedirectorProvider };
