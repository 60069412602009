import React, { useState, useEffect } from "react";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import MainHeaderFX from "../MainHeaderFx";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import api from "../../services/api";
import * as Yup from 'yup';
import { flattenFormikValidationErrors } from "../../utils/formatacao/flattenFormikValidationErrors";
import "./style.css";

import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions, 
  DialogContent, 
  DialogTitle,
  Divider,    
  useMediaQuery,
} from "@mui/material";

import {
  FlexFormatField,
  FlexTextField,
  FlexSelectField,
  FlexSelectSearchField
} from "../../components/input/Fields/FlexField";


const EmpresaModal = ({ open, onClose, empresaId, empresas, onClick=null }) => {

  const isSmallScreen = useMediaQuery('(max-width:825px)');

  const validationSchema = Yup.object().shape({
    razaoSocial: Yup.string().required('Razão Social é obrigatória'),
    cnpj: Yup.string().required('CNPJ é obrigatório'),
    inscricaoEstadual: Yup.string().required('I.E. é obrigatória'),
    
     endereco:
       Yup.object().shape({
         cep: Yup.string().required('CEP é obrigatório'),        
         logradouro: Yup.string().required('Endereço é obrigatório'), 
         numero: Yup.string().required('O número é obrigatório'),        
         bairro: Yup.string().required('O bairro é obrigatório'),
         uf: Yup.string().required('A UF é obrigatória'),
         municipioId: Yup.string().required('O Município é obrigatório'),     
       }),
      regimeTributario: Yup.object().shape({
        apuracaoImposto: Yup.string().required('A apuração do imposto é obrigatória'),      
      }),
  });

	const initialState = {
    razaoSocial: "",
    nomeFantasia: "",
    cnpj: "",
    inscricaoEstadual: "",    
    endereco: {
      cep: "",
      tipo: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      municipioId: "",  
      uf: "",      
    },
    regimeTributario: {
      apuracaoImposto: "", 
      cnaePrincipal: "",
    },
  };

  const [empresa, setEmpresa] = useState(initialState);
  const [municipios, setMunicipios] = useState([]);
  const [estados, setEstados] = useState([]);
  const [municipio, setMunicipio] = useState({id : 0, name: "", alterou: false});

  const arrayApuracaoImposto = ([{apuracao: "MEI"}, {apuracao: "SIMPLES NACIONAL"}]);    
  
  useEffect(() => {
		(async () => { 
			if (!empresaId) 
      {
        setEmpresa(prevState => { return { ...prevState, regimeTributario: {apuracaoImposto:arrayApuracaoImposto[1].apuracao} }; });
        return;
      }
			try {        
				const { data } = await api.get(`/v1/empresas/${empresaId}`);
        setEmpresa(prevState => { return { ...prevState, ...data, endereco:{...data.endereco, uf:data.endereco.municipio.estado.id} }; });

			} catch (error) { 
        toastError(error);
      }
		})();

		return () => { setEmpresa(initialState); };
	}, [empresaId, open]); 

  useEffect(() => {
		(async () => {
          setMunicipio({...municipio, alterou: false})
          if (municipio.name !== "" && municipio.name !== undefined && municipio.name !== null) {
            handleFindMunicipioId(municipio.name)
          }
          if (municipio.id !== 0 && municipio.id !== undefined && municipio.id !== null) {
            setEmpresa(prevState => { return { ...prevState, endereco: {...prevState.endereco, municipioId: municipio.id}} });
            return;
          }  
            
		})();
	}, [municipios]);  


  const fetchMunicipios = (estadoId) => {
    if (estadoId) {
        api.get(`/v1/estados/${estadoId}`).then((response) => {
          setMunicipios(response.data.municipios)
          return response.data.municipios;
        }).catch(() => {
          setMunicipios([])
          return municipios;
        })
      }
    else {
      setMunicipios([])
      return municipios;
    }
  }
  
  /* Municipios*/  
  useEffect(() => {    
    if (!municipio.alterou) {
      fetchMunicipios(empresa?.endereco?.uf)
    }
  }, [empresa]); 

  /* Estados*/
  useEffect(() => {    
    const fetchEstados = () => {
      api.get("/v1/estados", {params: {size:28}}).then((value) => {setEstados(value.data.estados)}).catch((error) => { toastError(error); });
    };

    fetchEstados(); 
  }, []); 

  const handleFindUfId = (value) => {  
    let id = "";

    estados.map((u) => {
      if (u.uf === value)
        id = u.id;
      fetchMunicipios(id) 
      return id;    
    });
    fetchMunicipios(id)
    return id;  
  }

  const handleFindMunicipioId = (value) => {  
    let id = "";

    municipios.map((u) => {
      if (u.nome === value) {
        id = u.id;
        setMunicipio({...municipio, id, alterou: true})
        return id;
      }  
    });

    return id;
  }
  
  const handleClose = (isRecording) => {
		onClose();
		setEmpresa(initialState);

    if (onClick && !isRecording) {
      onClick(false);
    }
	};

  const handleSaveEmpresa = async values => { 
		try {
      await validationSchema.validate(values, { abortEarly: false });

      const empresaData = { ...values };

			if (empresaId) { 
        await api.put(`/v1/empresas/${empresaId}`, empresaData);
        let empresaIndex = empresas.findIndex((emp) => emp.id === empresaId);
			  empresas[empresaIndex] = empresaData;
			} 
      else {     
				await api.post("/v1/empresas", empresaData);

        if (!onClick) {
          empresas.push(empresaData);
        }
			}

      if (onClick) {
        onClick(true);
      }

      toast.success(i18n.t("toasts.saved"));
			handleClose(true);
		} catch (error) {
      if (onClick) {
        onClick(false);
      }
      
      if (error instanceof Yup.ValidationError) {
        toast.error("Preecha os campos obrigatórios.");
      } else {
        toast.error(error);
      }
    }
	};  

  const handleSearchCep = (values) => {    
    try {     
      const cep = values.endereco.cep;    
      setEmpresa(values);

      if (cep) {
        fetch(`https://brasilapi.com.br/api/cep/v1/${cep}`)
          .then(result => result.json())
          .then(data => {
            const { city, state, neighborhood, street } = data;
            setMunicipio({...municipio, name: city, alterou: true}) 
            setEmpresa(prevState => ({...prevState, 
                                      endereco: {...empresa.endereco, 
                                                    cep, 
                                                    uf: handleFindUfId(state), municipioId: handleFindMunicipioId(city), 
                                                    bairro: neighborhood, 
                                                    logradouro: street} }));
          })
          .catch(error => { toastError(error); });
      }
    } catch (error) {
      toastError(error) 
    }
  }  

  const handleIconeCarregandoCnpj = (value) => {
    document.getElementById("iconeCNPJCarregando").style.display = value ? "block" : "none";
    document.getElementById("iconeCNPJNaoCarregando").style.display =  value ? "none" : "block";
    document.getElementById("btnBuscarCNPJ").disabled = value;

  }

  const handleSearchCNPJ = (values) => {    
    try {     
      const empresaData = { ...values };

      const cnpj = empresaData.cnpj;    
      setEmpresa(values);
      
      if (cnpj.length === 14) {
        handleIconeCarregandoCnpj(true);
        fetch(`https://publica.cnpj.ws/cnpj/${cnpj}`)
          .then(result => result.json())
          .then(data => {
            const { status } = data;
            
            if (status !== 429 && status !== 400)
            {
              const { razao_social } = data;
              const { nome_fantasia, numero, complemento, cep, bairro, logradouro } = data?.estabelecimento;
              const cidade = data?.estabelecimento?.cidade?.nome;
              const uf = data?.estabelecimento?.estado?.sigla;
              const cnaePrincipal = data?.estabelecimento?.atividade_principal?.subclasse;
              const inscricaoEstadual = data?.estabelecimento?.inscricoes_estaduais.length > 0 ? 
                                        data?.estabelecimento?.inscricoes_estaduais.find(ie => ie.estado.sigla === uf).inscricao_estadual : "";
  
              setMunicipio({...municipio, name: cidade, alterou: true}) 
  
              setEmpresa(prevState => ({...prevState, razaoSocial: razao_social, nomeFantasia: nome_fantasia, inscricaoEstadual,
                                                      regimeTributario: {...empresa.regimeTributario, cnaePrincipal},
                                                      endereco: {...empresa.endereco, 
                                                                    cep, 
                                                                    numero,
                                                                    complemento: complemento ? complemento : "",
                                                                    bairro,
                                                                    logradouro,
                                                                    uf: handleFindUfId(uf), municipioId: handleFindMunicipioId(cidade)} }));
            }
            handleIconeCarregandoCnpj(false);            
          })
          .catch(error => {
            toastError(error); 
          });
      }
      
    } catch (error) {
      toastError(error) 
    }
  }  

  return (
    <div>          
      <Dialog 
        open={open} 
        scroll="paper"
        fullScreen={isSmallScreen}  
        maxWidth="xl"  
        style={{ maxHeight: '100%', minHeight: '100%' }}    
      >
        <Formik 
          initialValues={empresa}
          enableReinitialize={true}     
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {     
            setTimeout(() => {
              
              handleSaveEmpresa(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values, validateForm, setFieldTouched }) => (
            <Form style={{display: "flex", flexDirection: "column", height: "100%", gap:"1em"}}>
              {isSmallScreen ? (
                <div style={{height: "10%"}}>
                  <MainHeaderFX
                      title={i18n.t("empresa.title")}
                      leftContainerType={"modal"}
                      rightContainerType={"modal"}
                      handleCloseModal={() => handleClose(false)}
                      handleSaveModal={async () => {
                        const validationErrors = await validateForm();
                        const flattenedValidationErrors = flattenFormikValidationErrors(validationErrors);
                        const fieldsWithErrors = Object.keys(flattenedValidationErrors);
                        
                        if (fieldsWithErrors.length === 0) {
                          handleSaveEmpresa(values);
                        }
                        else {
                          fieldsWithErrors.forEach(field => setFieldTouched(field, true, false));
                          toast.info(i18n.t("produto.toasts.requiredFieldsAreBlank"));
                        }
                      }}
                    />
                </div>
              ) : (
                <DialogTitle >
                  {i18n.t("empresa.title")}
                </DialogTitle>
              )}
              <Container style={{height: "90%", overflowY: "auto"}}>
                <DialogContent className="dialogContent" dividers>
                  <Container className="dataCompany"> 
                    <Container className="info">
                      <Container className="zipPostal" style={{width: isSmallScreen ? "100%" : "25%"}}>
                        <FlexFormatField
                          label={i18n.t("empresa.fields.cnpj")}
                          name="cnpj"
                          error={touched.cnpj && Boolean(errors.cnpj)}
                          helperText={touched.cnpj && errors.cnpj ? "" : undefined}
                          inputMode="numeric"
                          format="##.###.###/####-##"                    
                          allowEmptyFormatting={false}
                          autoFocus={true}
                          width="80%"  
                        /> 

                        <Button 
                          style={{ height: "50px", marginTop: "11px"}}                        
                          onClick={() => handleSearchCNPJ(values)}                                     
                          color="primary"
                          disabled={isSubmitting}
                          variant="contained"  
                          id="btnBuscarCNPJ"
                        >
                          <SyncIcon id="iconeCNPJCarregando" color="primary" style={{animation: "spin 1s ease infinite", display:"none"}}/>
                          <SearchOutlinedIcon id="iconeCNPJNaoCarregando" />
                        </Button>
                      </Container>  

                      <FlexTextField
                        label={i18n.t("empresa.fields.razaoSocial")}
                        name="razaoSocial"
                        error={touched.razaoSocial && Boolean(errors.razaoSocial)}
                        helperText={touched.razaoSocial && errors.razaoSocial ? "" : undefined}
                        width="53%"
                      />

                      <FlexTextField
                        label={i18n.t("empresa.fields.nomeFantasia")}
                        name="nomeFantasia"
                        error={touched.nomeFantasia && Boolean(errors.nomeFantasia)}
                        helperText={touched.nomeFantasia && errors.nomeFantasia ? "" : undefined}
                        width="53%"
                      />

                      <FlexFormatField
                        label={i18n.t("empresa.fields.inscricaoEstadual")}
                        name="inscricaoEstadual"
                        error={touched.inscricaoEstadual && Boolean(errors.inscricaoEstadual)}
                        helperText={touched.inscricaoEstadual && errors.inscricaoEstadual ? "" : undefined}
                        inputMode="numeric"
                        format="############"   
                        allowEmptyFormatting={false}
                        width="25%"
                      />             
                    </Container>

                    <div>
                    <br />
                    <b>{i18n.t("empresa.fields.endereco.title")}</b>
                    <Divider /><Divider />
                    <br />
                    

                    <Container className="endereco">
                      <Container className="zipPostal" style={{width: isSmallScreen ? "100%" : "20%"}}>
                        <FlexFormatField
                          label={i18n.t("empresa.fields.endereco.cep")}
                          name="endereco.cep"
                          error={touched.endereco && !!errors.endereco?.cep}
                          helperText={touched.endereco && errors.endereco?.cep ? "" : undefined}
                          inputMode="numeric"
                          format="#####-###"
                          allowEmptyFormatting={false}
                          width="80%"
                        />

                        <Button
                          style={{ height: "50px", marginTop: "11px"}}
                          onClick={() => handleSearchCep(values)}
                          color="primary"
                          disabled={isSubmitting}
                          variant="contained"
                        >
                          <SearchOutlinedIcon/>                       
                        </Button>                      
                      </Container>  
                        
                      <FlexTextField
                        label={i18n.t("empresa.fields.endereco.logradouro")}
                        name="endereco.logradouro"
                        error={touched.endereco && !!errors.endereco?.logradouro}
                        helperText={touched.endereco && errors.endereco?.logradouro ? "" : undefined}
                        width="59%"
                      />

                      <FlexTextField
                        label={i18n.t("empresa.fields.endereco.numero")}
                        name="endereco.numero"
                        error={touched.endereco && !!errors.endereco?.numero}
                        helperText={touched.endereco && errors.endereco?.numero ? "" : undefined}
                        width="18%"
                      />

                      <FlexTextField
                        label={i18n.t("empresa.fields.endereco.complemento")}
                        name="endereco.complemento"
                        width="40%"
                      />

                      <FlexTextField
                        label={i18n.t("empresa.fields.endereco.bairro")}
                        name="endereco.bairro"
                        error={touched.endereco && !!errors.endereco?.bairro}
                        helperText={touched.endereco && errors.endereco?.bairro ? "" : undefined}
                        width="58%"
                      />

                      <FlexSelectField                         
                        label={i18n.t("UF")}
                        name="endereco.uf"
                        arrayList={estados}
                        propArray={"uf"}
                        propArrayId={"id"}                      
                        error={touched.endereco && !!errors.endereco?.uf}
                        helperText={touched.endereco && errors.endereco?.uf ? "" : undefined}
                        width={"10%"}
                        style={{backgroundColor: "red"}} 
                        customOnChange={(event) => {setMunicipio({id: null, municipioId: null, alterou: false}); fetchMunicipios(event.target.value)}}
                      /> 

                      <FlexSelectSearchField
                        width={"88%"}
                        label={i18n.t("empresa.fields.endereco.municipio")}
                        name="endereco.municipioId"
                        arrayList={municipios}
                        propArray={"nome"}
                        propArrayId={"id"}
                        
                        error={touched.endereco && !!errors.endereco?.municipioId}
                        helperText={touched.endereco && errors.endereco?.municipioId ? "" : undefined}
                      />
                    </Container>
                    </div>
                  </Container> 
                    <div style={{paddingLeft: "10px"}}>
                    <br />
                    <b >{i18n.t("empresa.fields.tributacao.title")}</b>
                    <Divider /><Divider />
                    <br />   
                    </div>             
      
                  <Container className="regimeTributarioFields">
                  
                    <FlexSelectField 
                      name="regimeTributario.apuracaoImposto"
                      label={i18n.t("empresa.fields.tributacao.apuracaoImposto")}
                      arrayList={arrayApuracaoImposto}
                      propArrayId={"apuracao"}
                      propArray={"apuracao"}
                      error={touched.apuracaoImposto && Boolean(errors.apuracaoImposto)}
                      helperText={touched.apuracaoImposto && errors.apuracaoImposto ? "" : undefined}
                      width={"58%"}  
                      applyNegativeMargin={true}     
                    />

                    <FlexFormatField
                      label={i18n.t("empresa.fields.tributacao.cnaePrincipal")}
                      name="regimeTributario.cnaePrincipal"
                      inputMode="numeric"
                      format="####-#/##"
                      allowEmptyFormatting={false}
                      width="28%"
                    />   
                  </Container>   
                </DialogContent>   
              </Container>

              {!isSmallScreen && (
                <DialogActions>
                  <Button
                    onClick={() => handleClose(false)}
                    // onClick={() => {
                    //   if (onClick ) {
                    //     onClick(false);
                    //   }
                    //   else
                    //     {handleClose();}
                    // }}
                    color="inherit"                      
                    disabled={isSubmitting}
                    variant="outlined"                      
                  >
                    {"cancelar"}
                  </Button>

                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"                      
                  >
                    {empresaId ? `${"Salvar"}` : `${"Adicionar"}`}
                    {isSubmitting && ( <CircularProgress size={24} />)}
                  </Button>
                </DialogActions>
              )}     
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default EmpresaModal;