import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const DialogModal = ({ title, description, open, onClose, buttons }) => {
	return (
		<Dialog open={open} onClose={() => onClose("")}>
			<DialogTitle >{title}</DialogTitle>

			<DialogContent dividers>{description}</DialogContent>

			<DialogActions>
				{buttons?.map(button => (<Button onClick={() => onClose(button)}>{button}</Button>))}	
			</DialogActions>
		</Dialog>
	);
};

export default DialogModal;
