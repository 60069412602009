import { gerarDescontoItens } from "../../calculos/gerarDescontoItem";

export const gerarTotalizadorItens = (json, valorDesconto, subTotal, usarBase) => {
	let infTotais;
	
	if (valorDesconto > 0) {
		const itens = gerarDescontoItens(json.item, valorDesconto, "valorDesconto");
  
		const newJson = {
		  ...json, 
		  
		  ...itens                
		};
		if (usarBase) {
			infTotais = {...newJson, icmsTot: { 
				...json.icmsTot, 
				valorProduto: subTotal,
				valorDesconto: valorDesconto,
				totalNf: subTotal - valorDesconto
			}}  
		} else {
			infTotais = {
				icmsTot: { 
				totalTributos: 0,
				valorProduto: subTotal,
				valorDesconto: valorDesconto,
				totalNf: subTotal - valorDesconto
			}}  	
		}    
	  } 
	  else  {     
		if (usarBase) { 
			infTotais = {...json, icmsTot: { 
			...json.icmsTot, 
			valorProduto: subTotal,
			valorDesconto: valorDesconto,
			totalNf: subTotal - valorDesconto,
			}}  
		} else {
			infTotais = {
				icmsTot: { 
				totalTributos: 0,
				valorProduto: subTotal,
				valorDesconto: valorDesconto,
				totalNf: subTotal - valorDesconto
			}}  
		}
	  }   

	return infTotais;
}