export const flattenFormikValidationErrors = (errors) => {
	const flattenedErrors = {};

	const flatten = (object, parent='') => {
		for (const key in object) {
			if (object.hasOwnProperty(key)) {
				const newKey = parent ? `${parent}.${key}` : key;
				if (typeof object[key] === "object" && object[key] !== null) flatten(object[key], newKey);
				else flattenedErrors[newKey] = object[key];
			}
		}
	};

	flatten(errors);
	return flattenedErrors;
};