import { Configuracao } from "../../../configuracao";
import api from "../../../services/api";
import { buscarNaturezaOperacao } from "./funcoes/buscarNaturezaOperacao";

export const gerarIde = async (operacaoOpcao, identificador, empresa, dest, chaveReferencia, interestadual) => {
  try {    	
    const configuracaoResponse = await api.get(`v1/empresas-configuracoes-payload/${empresa.id}`);
    const configuracaoNfce = configuracaoResponse.data.configuracaoNfce;        

    const naturezaOperacao = buscarNaturezaOperacao(configuracaoResponse.data.tributacao.operacao);

    const chaveReferenciaValue = chaveReferencia !== undefined ? chaveReferencia : identificador?.NFref?.NFref;

    const ide = {
      id: operacaoOpcao.alterar ? identificador.id : undefined,
      cUF: empresa.endereco.municipio.estado.codigoIbge, 
      natOp: operacaoOpcao.devolver ? "Devolução de Mercadoria" : naturezaOperacao,
      finNFe: operacaoOpcao.devolver ? "4" : identificador.finNFe,
      indFinal: identificador.indFinal,
      mod: operacaoOpcao.converter ? "55" : identificador.mod,
      tpImp: operacaoOpcao.converter ? "1" : identificador.tpImp,
      serie: configuracaoNfce.serie || "1",
      nNF: null,
      dhEmi: null,
      tpNF: operacaoOpcao.devolver ? "0" : identificador.tpNF,
      idDest: interestadual || (dest?.endereco && dest?.endereco?.uf !== empresa.endereco.municipio.estado.uf) ? "2" : "1",      
      cMunFG: empresa.endereco.municipio.codigoIbge,
      tpEmis: identificador.tpEmis,
      cDV: null,      
      tpAmb: configuracaoResponse.data.configuracao.ambiente === "Produção" ? "1" : "2",
      indPres: identificador.indPres,
      procEmis: "0",
      verProc: Configuracao.VERSAO_SISTEMA,
      operacao: configuracaoResponse.data.tributacao.operacao,
      NFref: chaveReferenciaValue !== undefined && (operacaoOpcao.converter || operacaoOpcao.devolver) ? { notaIdeId: identificador.id, refNFe: chaveReferenciaValue} : null,
    };
    
    return ide;
  } catch (error) {        
    throw error; 
  }
};