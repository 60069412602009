import React, {  useEffect, useRef, useState } from "react";
import {MenuItem, Menu, Divider} from "@mui/material";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import VisualizarNFCe from "../VisualizarNFCe";
import DialogModal from "../DialogModal";
import api from "../../services/api";
import { toast } from "react-toastify";
import { geraJsonNota } from "../../utils/funcoes/geraJsonNota/geraJsonNota";
import InputDialogModal from "../InputDialogModal";
import InformarCliente from "../InformarCliente";
import DevolucaoItensModal from "../DevolucaoItensModal";
import LoadingModal from "../Modal/LoadingModal";

const VendasOptionsMenu = ({ nota, menuOpen, handleClose, anchorEl, isSmallScreen, onAtualizar, clientX, clientY }) => {
	const isMounted = useRef(true);
	const [visualizarNota, setVisualizarNota] = useState({nota: nota, open: false}); 
	const [dialogModal, setDialogModal] = useState({open: false, keyword: "", title: "", description: "", buttons: [], result: ""});
	const [inputDialogModal, setInputDialogModal] = useState({open: false, keyword: "", title: "", description: "", result: "", answer: ""});
	const [cliente, setCliente] = useState(null); 
	const [informarCliente, setInformarCliente] = useState(false);
	const [devolucaoModal, setDevolucaoModal] = useState({nota, open: false}); 
	const [loadingScreen, setLoadingScreen] = useState(false);
	
	useEffect(() => {
		return () => { isMounted.current = false; };
	}, []);

	const handleVisualizar = async () => {
		const notaResponse = await api.get(`/v1/notas/${nota.id}`);
		setVisualizarNota({...visualizarNota, nota: notaResponse.data, open: true});
		handleClose();
	}

	const baixar = async (file) => {
		const fileName = `${nota.chave}${file.name}.${file.ext}`;
			const url = window.URL.createObjectURL(
				new Blob([file.file], {
				type: `application/${file.ext}`,
				encoding: 'null'
				})
			);

			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			link.remove()
	}

	const handleDownload = async () => {

		const { data: xmlFile } = await api.get(`/v1/notas/${nota.id}/xml/`);
		const xml = {file: xmlFile, ext: "xml", name: ""};

		let files = [];

		if (nota.cStat === "100") {
			const { data: pdfFile } = await api.get(`/v1/notas/${nota.id}/pdf/`, {  responseType: 'blob', encoding: null, ContentType: "application/pdf" });
			const pdf = {file: pdfFile, ext: "pdf", name: ""};	
			files = [pdf, xml];
		}
		else {
			if (nota.situacao === "Cancelada")  { /*Cancelada*/
				const { data: xmlFileCancelada } = await api.get(`/v1/notas/${nota.id}/xml_cancelado/`);
				const xmlCancelada = {file: xmlFileCancelada, ext: "xml", name: "_cancelado"};
				files = [xml, xmlCancelada];
			} else {
				files = [xml];
			}	
		}

		files.forEach((file) => {
			baixar(file);
		});

		handleClose();
	}

	const handleCompartilhar = async () => {
		try {
			//const { data: xmlFile } = await api.get(`/v1/notas/${nota.id}/xml/`, { responseType: 'blob', encoding: null, ContentType: "text/plain" });
			const { data: pdfFile } = await api.get(`/v1/notas/${nota.id}/pdf/`, { responseType: 'blob', encoding: null, ContentType: "application/pdf" });

			//const xml = new File([xmlFile], nota.chave + ".txt", {type: "text/plain"});
			const pdf = new File([pdfFile], nota.chave + ".pdf", {type: "application/pdf"});

			await navigator.share({
				files: [pdf]
			});
		} catch (error) {
			setDialogModal({...dialogModal, keyword: "compartilhar", open: true, title: "Erro ao compartilhar", description: `Compartilhamento indisponível no momento. ${error.message}`, buttons: ["OK"]});
		}

		handleClose();
	}

	const handleImprimir = async () => {
		try {
			const { data: pdfFile } = await api.get(`/v1/notas/${nota.id}/pdf/`, { responseType: 'blob', encoding: null, ContentType: "application/pdf" });
			const pdf = new File([pdfFile], nota.chave + ".pdf", {type: "application/pdf"});
			const pdfUrl = URL.createObjectURL(pdf, { type: 'application/pdf' });
			const newWindow = window.open(pdfUrl);

			newWindow.onload = () => {
				newWindow.print();
			};
		} catch (error) {
			setDialogModal({...dialogModal, keyword: "compartilhar", open: true, title: "Erro ao imprimir", description: `Impressão indisponível no momento. ${error.message}`, buttons: ["OK"]});
		}

		handleClose();
	}

	const handleVisualizarErro = () => { 
		setDialogModal({...dialogModal, keyword: "erro", open: true, title: "Mensagem de Erro", description: nota.mensagem, buttons: ["OK"]});
		handleClose();
	}

	function copyToClipboard(text) {
		if (navigator.clipboard) {
		  navigator.clipboard.writeText(text).then(() => {
			console.log('Texto copiado para a área de transferência!');
		  }).catch(err => {
			console.error('Erro ao copiar texto: ', err);
		  });
		}
	  }

	const handleCopiarChave = () => { 
		copyToClipboard(nota.chave);
		setDialogModal({...dialogModal, keyword: "info", open: true, title: "Informação", description: "A chave foi copiada para a área de transferência!", buttons: ["OK"]});
		handleClose();
	}
	
	const handleCorrigirReenviar = async () => {
		try {
			setLoadingScreen(true);	
			const buscarNota = await api.get(`/v1/notas/${nota.id}`);
			const jsonNota = await geraJsonNota(buscarNota.data, 'ALTERAR')

			if (jsonNota.status === 200) {
				const envio = await api.put(`/v1/notas/${nota.id}`, jsonNota.nota);
				
				if (envio.data.cStat === '100') {
					toast.success(`Nota enviada ${envio.data.chave}`);
				} else {                        
					toast.error(`Erro ao recriar ${envio.data.mensagem}`);
				} 
				onAtualizar(envio.data)
			} 
			else {
				toast.error(`Erro ao recriar ${jsonNota?.message}`);
			}
		} catch (error) { 			
			const errorMsg = error.response?.data?.message || error.response.data.error;       
			toast.error(`Erro ao recriar ${errorMsg}`);
		}
		handleClose();
		setLoadingScreen(false);	
	}

	const handleCancelarNotaJustificativa = () => {
		setInputDialogModal({...inputDialogModal, keyword: "cancelar", open: true, title: "Cancelar nota", description: "Informe a justificativa", answer: ""});
		handleClose();
	}

	const handleInutilizarNotaJustificativa = () => {
		setInputDialogModal({...inputDialogModal, keyword: "inutilizar", open: true, title: "Inutilizar nota", description: "Informe a justificativa", answer: ""});
		handleClose();
	}

	const cancelarNota = async (answer) => {
		try {
			setLoadingScreen(true);	
			const resposta = await api.put(`/v1/notas/${nota.id}/cancelar/`, {xJust: answer});
			if (resposta.status === 200 && resposta.data.sucesso) {
				setDialogModal({...dialogModal, keyword: "info", open: true, title: "Aviso", description: "Nota cancelada com sucesso!", buttons: ["OK"]});
				nota.cStat = "101";
				nota.situacao = resposta.data.situacao;
				handleClose(true);
			} else {
				setDialogModal({...dialogModal, keyword: "info", open: true, title: "Aviso", description: "Não foi possivel cancelar nota! \n "+resposta.data.mensagem, buttons: ["OK"]});
			}	
		} catch (error) {
			setDialogModal({...dialogModal, keyword: "info", open: true, title: "Aviso", description: "Erro ao cancelar nota!", buttons: ["OK"]});
		}

		handleClose(false);
		setLoadingScreen(false);	
	}

	const inutilizarNota = async (answer) => {
		try {
			setLoadingScreen(true);	
			const resposta = await api.put(`/v1/notas/${nota.id}/inutilizar/`, {xJust: answer});
			if (resposta.status === 200 && resposta.data.sucesso) {
				setDialogModal({...dialogModal, keyword: "info", open: true, title: "Aviso", description: "Nota inutilizada com sucesso!", buttons: ["OK"]});
				nota.cStat = "102";
				nota.situacao = resposta.data.situacao;
				handleClose(true);
			} else {
				setDialogModal({...dialogModal, keyword: "info", open: true, title: "Aviso", description: "Não foi possivel inutilizar nota! \n "+resposta.data.mensagem, buttons: ["OK"]});
			}	
		} catch (error) {
			setDialogModal({...dialogModal, keyword: "info", open: true, title: "Aviso", description: "Erro ao inutilizar nota!", buttons: ["OK"]});
		}

		handleClose(false);
		setLoadingScreen(false);	
	} 

	const handleCloseDialogModal = (result) => {
		const keyword = dialogModal.keyword;
		setDialogModal({...dialogModal, keyword: "", open: false, result: result});
		
		if (result === "Sim" )
			switch (keyword) {
				case "cancelar": {
					handleCancelarNotaJustificativa();
					break;
				}
				case "inutilizar": {
					handleInutilizarNotaJustificativa();
					break;
				}
			}
	};

	const handleCloseInputDialogModal = (result, answer) => {
		const keyword = inputDialogModal.keyword;
		setInputDialogModal({...inputDialogModal, keyword: "", open: false, result: result, answer: answer});
		
		if (result === "Confirmar" ) {
			switch (keyword) {
				case "cancelar": {
					if (answer.length < 15)
						setDialogModal({...dialogModal, keyword: "info", open: true, title: "Aviso", description: "A justificativa deve ter pelo menos 15 caracteres!", buttons: ["OK"]});		
					else
						cancelarNota(answer);
					break;
				}
				case "inutilizar": {
					if (answer.length < 15)
						setDialogModal({...dialogModal, keyword: "info", open: true, title: "Aviso", description: "A justificativa deve ter pelo menos 15 caracteres!", buttons: ["OK"]});		
					else
						inutilizarNota(answer);
					break;
				}
			}	
		}
	};

	const handleCloseVisualizarModal = () => {
		setVisualizarNota({...visualizarNota, open: false});
	};

	const handleChecarCompartilhamentoDisponivel = () => {
		try {
			return navigator.canShare;	
		} catch (error) {
			return false;	
		}
	}

	const handleChecarClipboardDisponivel = () => {
		try {
			return navigator.clipboard;	
		} catch (error) {
			return false;
		}
	}

	const handleCloseLoadingModal = () => {    
		setLoadingScreen(false);
	}

	useEffect(() => {
		if (cliente && cliente?.id > 0) {      
			gerarNFDaNFCe()      
		}
	  }, [cliente])
	  
	const gerarNFDaNFCe = async () => {
		try {
			setLoadingScreen(true);	
			let buscarNota = await api.get(`/v1/notas/${nota.id}`);
			buscarNota = {...buscarNota.data, dest:  { clienteId: cliente.id }}
			const jsonNota = await geraJsonNota(buscarNota, 'CONVERTER')

			if (jsonNota.status === 200) {
				const envio = await api.post(`/v1/notas/`, jsonNota.nota);
				
				if (envio.data.cStat === '100') {
					toast.success(`Converter para NF-e: Nota recriada ${envio.data.chave}`);
				} else {                        
					setDialogModal({...dialogModal, keyword: "info", 
									open: true, 
									title: "Não foi possivel gerar a nota!", 
									description: `${envio.data.mensagem}`, 
									buttons: ["OK"]});
				} 
				onAtualizar(envio.data)
			} 
			else {
				setDialogModal({...dialogModal, keyword: "info", 
								open: true, 
								title: "Não foi possivel gerar a nota!", 
								description: `${jsonNota?.message}`, 
								buttons: ["OK"]});
			}
		} catch (error) { 			
			const errorMsg = error.response?.data?.message || error.response.data.error;       
			setDialogModal({...dialogModal, keyword: "info", 
							open: true, 
							title: "Não foi possivel gerar a nota!", 
							description: `${errorMsg}`, 
							buttons: ["OK"]});
		}
		handleClose();
		setLoadingScreen(false);	
	}

	const handleGerarNFeDaNFCe = () => {
		setInformarCliente(true)
	}

	const handleCloseModalInformarCliente = (clienteValue) => {    
		setInformarCliente(false);  
		setCliente(clienteValue ? clienteValue : null);
	}

	const handleGerarNFeDevolucao = async () => {
		const notaResponse = await api.get(`/v1/notas/${nota.id}`);
		setDevolucaoModal({...devolucaoModal, nota: notaResponse.data, open: true});
		handleClose();
	}

	const handleCloseDevolucaoModal = () => {
		setDevolucaoModal({...devolucaoModal, open: false});
	};
	
	return (
		<>
			<LoadingModal
				open={loadingScreen}
				onClose={handleCloseLoadingModal}
			/>  
			<InformarCliente
				open={informarCliente}
				onClose={handleCloseModalInformarCliente}
			/> 
			<VisualizarNFCe
				isSmallScreen={isSmallScreen}
				open={visualizarNota.open}
				onClose={handleCloseVisualizarModal}  
				nota={visualizarNota.nota}       
				>				
			</VisualizarNFCe>
			<DevolucaoItensModal
				isSmallScreen={isSmallScreen}
				open={devolucaoModal.open}
				onClose={handleCloseDevolucaoModal}  
				nota={devolucaoModal.nota}    
				onAtualizar={onAtualizar}   
				>				
			</DevolucaoItensModal>
			<DialogModal
				title={dialogModal.title}
				open={dialogModal.open}
				onClose={handleCloseDialogModal}  
				description={dialogModal.description}   
				buttons={dialogModal.buttons}      
				>				
			</DialogModal>
			<InputDialogModal
				title={inputDialogModal.title}
				open={inputDialogModal.open}
				onClose={handleCloseInputDialogModal}  
				description={inputDialogModal.description}        
				>				
			</InputDialogModal>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorPosition={{ top: clientY, left: clientX }}
				anchorReference= {isSmallScreen ? "anchorEl" : "anchorPosition"}
				getcontentanchorel={null}
				anchorOrigin={{ vertical: "top", horizontal: isSmallScreen ?  "right" : "left", }}
				keepMounted
				transformOrigin={{ vertical: "top", horizontal: isSmallScreen ?  "right" : "left", }}
				open={menuOpen}
				onClose={handleClose}
			>	
				{/* autorizada */}
				{nota && nota.cStat === "100" && (<div>
				<MenuItem onClick={handleDownload}>{i18n.t("vendas.table.acoesLista.download")}</MenuItem>
				{handleChecarCompartilhamentoDisponivel() && <MenuItem onClick={handleCompartilhar}> {i18n.t("vendas.table.acoesLista.compartilhar")}</MenuItem>}
                <MenuItem onClick={handleVisualizar}>{i18n.t("vendas.table.acoesLista.visualizar")}</MenuItem>
				<MenuItem onClick={handleImprimir}>{i18n.t("vendas.table.acoesLista.imprimir")}</MenuItem>
				{handleChecarClipboardDisponivel() && <MenuItem onClick={handleCopiarChave}>{i18n.t("vendas.table.acoesLista.copiarChave")}</MenuItem>}
				{nota && nota.cStat === "100" && (<div><Divider/></div>)}
				{nota && nota.ide.mod === "65" && nota.cStat === "100" && 
				(<div>
					<MenuItem onClick={handleGerarNFeDaNFCe}>{i18n.t("vendas.table.acoesLista.gerarNfeDaNfce")}</MenuItem>
				</div>)}
				{nota && nota.cStat === "100" && nota.ide.finNFe === "1" &&
				(<div>
					<MenuItem onClick={handleGerarNFeDevolucao}>{i18n.t("vendas.table.acoesLista.gerarDevolucao")}</MenuItem>
				</div>)}
				
				<Divider/>
				<MenuItem onClick={handleCancelarNotaJustificativa}>{i18n.t("vendas.table.acoesLista.cancelarNota")}</MenuItem></div>)}
				
				{/* cancelada ou inutilizada */}
				{nota && (nota.cStat === "101" || nota.cStat === "102") && (<div>
				{handleChecarCompartilhamentoDisponivel() && <MenuItem onClick={handleCompartilhar}> {i18n.t("vendas.table.acoesLista.compartilhar")}</MenuItem>}
				<MenuItem onClick={handleDownload}>{i18n.t("vendas.table.acoesLista.download")}</MenuItem>
				<MenuItem onClick={handleVisualizar}>{i18n.t("vendas.table.acoesLista.visualizar")}</MenuItem>
				{handleChecarClipboardDisponivel() && <MenuItem onClick={handleCopiarChave}>{i18n.t("vendas.table.acoesLista.copiarChave")}</MenuItem>}</div>)}
				
				{/* rejeitada */}
				{nota && nota.cStat !== "100" && nota.cStat !== "101" && nota.cStat !== "102" && (<div>
				<MenuItem onClick={handleVisualizarErro}> {i18n.t("vendas.table.acoesLista.visualizarErro")}</MenuItem>
				<MenuItem onClick={handleCorrigirReenviar}>{i18n.t("vendas.table.acoesLista.corrigirReenviar")}</MenuItem>
				{handleChecarClipboardDisponivel() && <MenuItem onClick={handleCopiarChave}>{i18n.t("vendas.table.acoesLista.copiarChave")}</MenuItem>}
				<Divider/>
				<MenuItem onClick={handleInutilizarNotaJustificativa}>{i18n.t("vendas.table.acoesLista.inutilizar")}</MenuItem></div>)}
				
			</Menu>
		
		</>
	);
};

export default VendasOptionsMenu;