import React, {  useEffect, useRef, useState } from "react";
import Lottie from "react-lottie";
import LoadingLottie from "../../../assets/lottie/loading.json";
import { useMediaQuery } from "@mui/system";
import { Dialog, DialogTitle } from "@mui/material";

const LoadingModal = ({ open, onClose }) => {

	const LottieLoadingOptions = {
		animationData: LoadingLottie,
		loop: true,
		background: "transparent",
		speed: "1",
		direction: "1",
		playMode: "normal",
		autoplay: true,
	  };

	  const isSmallScreen = useMediaQuery('(max-width:825px)'); 
		
	return (
		<>
			<Dialog
				open={open}
			>
				<Lottie
				options={LottieLoadingOptions}
				style={{ width: isSmallScreen ? "75%" : "75%",  height: isSmallScreen ? "30%" : "30%", }}
				isClickToPauseDisabled={true}
				/>
				<DialogTitle style={{textAlign: "center"}}><strong>Processando...</strong></DialogTitle>
			</Dialog>

					
		</>
	);
};

export default LoadingModal;


























