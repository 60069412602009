import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, IconButton, Typography, useMediaQuery } from "@mui/material";
import { LineChart } from '@mui/x-charts/LineChart';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';           

import { AuthContext } from "../../context/Auth/AuthContext";
import { Configuracao } from "../../configuracao";
import { i18n } from "../../translate/i18n.js";
import api from "../../services/api";
import EmissaoModal from "../../components/Modal/EmissaoModal";
import FlexMessage from "../../components/message/FlexMessage/index.js";
import toastError from "../../errors/toastError.js";

import "./style.css"

const Main = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const urlPage = window.location.search;
  const urlParams = new URLSearchParams(urlPage);
  
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:825px)');
  const { setDrawerOpen, user } = useContext(AuthContext);

  const [errorNota, setErrorNota] = useState(false); 
  const [mensageError, setMensageError] = useState("");   
  const [graficoDia, setGraficoDia] = useState([]);
  const [graficoMes, setGraficoMes] = useState([]);  
  const [typeGrafic, setTypeGrafic] = React.useState(true);
  const [emissaoModal, setOpenModalEmissao] = useState(false);  
  const [emissaoModalParametro] = useState(urlParams.get('emitirNfce') === '1');

  const [idEmpresa, setIdEmpresa] = useState(null);
  const [isIdEmpresaChecked, setIsIdEmpresaChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [diasCertificadoVencimento, setDiasCertificadoVencimento] = useState(0);
  const [isCertificateExistent, setisCertificateExistent] = useState(false);


  //  ***************
  //  ** Callbacks **
  //  ***************
  const handleNavigateToCallback = useCallback((path) => {
    setTimeout(() => {
      navigate(path);
    }, 1);
  }, [navigate]);

  const handleOpenModalEmissaoCallback = useCallback(async (idEmpresaValue) => {
    const idEmpresaApi = idEmpresaValue > 0 ? idEmpresaValue : idEmpresa;

    try {
      const { data } = await api.get(`/v1/empresas-configuracoes-payload/${idEmpresaApi}`);
      
      if (!data.configuracaoNfce || !data.certificado) {
        const messageError = !data.configuracaoNfce
          ? "Configuração de Nota"
          : "Informe o certificado";

        const errorNota = !data.configuracaoNfce
          ? "Para emissão da nota, é necessário finalizar as configurações. \n Por favor, vá ao menu Configurações e preencha as informações."
          : "Para emissão da nota, é obrigatório informar o certificado digital. \n Por favor, vá ao menu Configurações e configure-o.";

        setMensageError(messageError);
        setErrorNota(errorNota);
        return;
      }

      setOpenModalEmissao(true);
    }
    
    catch (exception) {
      toastError(exception);
    } 
  }, [idEmpresa]);



  //  *****************
  //  ** Use Effects **
  //  *****************
  // useEffect(() => {
  //   if (isUpdated) window.location.reload(true);
  // }, [isUpdated]);

  // useEffect(() => {
  //   fetch('/version.txt')
  //     .then(response => response.text())
  //     .then(version => {
  //       if (version !== localStorage.getItem('frontend_version')) {
  //         localStorage.setItem('frontend_version', version);
  //         setIsUpdated(true);
  //       }
  //     });
  // }, []);

  useEffect(() => {
    const fetches = async () => {
      try {
        const apiCalls = [api.get("v1/graficos-iniciais/"), api.get("/v1/empresas")];
        const [ResponseGrafico, ResponseEmpresa] = await Promise.all(apiCalls);
        
        const idEmpresaResponse = ResponseEmpresa.data.empresas[0]?.id;

        setIsIdEmpresaChecked(true);

        if (idEmpresaResponse) {
          setIdEmpresa(idEmpresaResponse);
          setGraficoMes(ResponseGrafico.data.mensal);
          setGraficoDia(ResponseGrafico.data.diaria);
          if (emissaoModalParametro) await handleOpenModalEmissaoCallback(idEmpresaResponse);
        }
        else handleNavigateToCallback("/wizard");
      } catch (exception) {
        toastError(exception);
      }
    };

    fetches();
  }, [emissaoModalParametro, handleOpenModalEmissaoCallback, handleNavigateToCallback]);

  useEffect(() => {
    if (!isModalOpen) {
      refreshGrafico();
    }
  }, [isModalOpen]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.get(
          `/v1/empresas-configuracoes-payload/${idEmpresa}`,
        );
        const hoje = new Date();
        const validade = new Date(data.certificado.dataValidade);
        const diferencaEmMilissegundos = validade - hoje;
        const diferencaEmDias = Math.ceil(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));
        setDiasCertificadoVencimento(diferencaEmDias);
        setisCertificateExistent(true);
      } catch (error) {
        setisCertificateExistent(false);
        console.error(error);
      }
    };
  
    fetchData();
  }, [idEmpresa, diasCertificadoVencimento]);
  

  //  ***************
  //  ** Functions **
  //  ***************
  const handleNavigateTo = (path) => { 
    setTimeout(() => {
      navigate(path); 
    }, 1);
  };

  const handleOpenModalEmissao = async (idEmpresaValue) => {       
    const idEmpresaApi = idEmpresaValue > 0 ? idEmpresaValue : idEmpresa;
    try {
      setIsModalOpen(true);

      const { data } = await api.get(
        `/v1/empresas-configuracoes-payload/${idEmpresaApi}`,
      );

      if (!data.configuracaoNfce) {
       setMensageError("Configuração de Nota")
       setErrorNota("Para emissão da nota, é necessário finalizar as configurações. \nPor favor, vá ao menu Configurações e preencha as informações.")
      } 
      else if (!data.certificado) {
        setMensageError("Informe o certificado")
        setErrorNota("Para emissão da nota, é obrigatório informar o certificado digital. \nPor favor, vá ao menu Configurações e configure-o.")
      } 
      else {
        setOpenModalEmissao(true); 
      }
    } catch (error) {
      toastError(error);
    }   
  };

  const refreshGrafico = async () => {
    try {
      const [dataGrafico] = await Promise.all([api.get("v1/graficos-iniciais/")]);
      setGraficoMes(dataGrafico.data.mensal);
      setGraficoDia(dataGrafico.data.diaria);
    } catch (error) {
      toastError(error);
    }
  };
  
  const handleCloseModalEmissao = () => {
    setOpenModalEmissao(false);
    setIsModalOpen(false);
  }

  const handleTypeGraficAnual = () => { setTypeGrafic(false); }

  const handleTypeGraficMensal = () => { setTypeGrafic(true); }

  const mesAbreviado = (valor) => {
    if (valor === null || valor === undefined) {
      throw new Error('O valor de mes/ano não pode ser nulo nem indefinido');
    }
    
    let resultadoFormatado = '';
    let mesAno = [];

    // ***---- Monthly Chart ----***
    if (!typeGrafic) {
      const [ano, mes] = valor.split('-');  
      const mesesAbreviados = ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
      const nomeMesAbreviado = mesesAbreviados[parseInt(mes, 10) - 1];
      resultadoFormatado = `${nomeMesAbreviado}\n${ano}`;      
    }
    
    // ***---- Daily Chart ----***
    else {
      mesAno = valor.split('-');
      resultadoFormatado = `${mesAno[2]}/${mesAno[1]}`;
    }

    return resultadoFormatado;
  };



  //  ************
  //  ** Return **
  //  ************
  if (!idEmpresa && isIdEmpresaChecked) navigate("/wizard");

  if (!idEmpresa) return <></>;

  return (
    <>
    <EmissaoModal
      open={emissaoModal}
      onClose={handleCloseModalEmissao}
      urlPage={urlPage}
    />    

    <FlexMessage
      open={errorNota}
      onClose={() => setErrorNota(false)}
      title={mensageError}
      tipo={"error"}
      message={errorNota}
    />

    <Container className="mainContainer">
      <Container className="information" style={isSmallScreen ? {height:"40vh"} : {height:"50vh"}}>
        <Container className={isSmallScreen ? "informationInitialCelular" : "informationInitialComputador"}>
          {isSmallScreen && (
            <Container className="iconMenu">
              <IconButton onClick={() => setDrawerOpen(previousValue => !previousValue)}>
                <MenuOutlinedIcon style={{color: Configuracao.COR_SECUNDARY, fontSize: 35}} />
              </IconButton>
            </Container>
          )}

          <Typography style={{ fontSize: isSmallScreen ? '12px' : '16px', marginRight: '0em', }}>
            Bem-vindo <b>{user.name}</b>!
          </Typography>
          <Typography 
            style={{
              fontSize: isSmallScreen ? '16px' : '22px', 
              marginRight: '0em', 
              fontWeight: 'bold'
            }}>
              Resumo das Vendas
          </Typography>
          
        </Container>
        
        <Container className="grafic">
          <Container className="graficOptions">
            <Button 
              variant="text" 
              onClick={handleTypeGraficMensal}
              style={{ color: typeGrafic ? Configuracao.COR_4 : Configuracao.COR_PRIMARY,  left: 35, textTransform: 'capitalize' }}
            >
              Diário
            </Button>

            <Button
              variant="text" 
              onClick={handleTypeGraficAnual}
              style={{ color: typeGrafic ? Configuracao.COR_PRIMARY : Configuracao.COR_4, left: 35, textTransform: 'capitalize',  }}
            >
              Mensal
            </Button>
          </Container>

          <LineChart
            dataset={typeGrafic ? graficoDia : graficoMes}
            xAxis={[{
              scaleType: 'band',
              dataKey: typeGrafic ? 'data' : 'anoMes',
              valueFormatter: (date, context) =>
                context.location === 'tick'
                  ? mesAbreviado(date)
                  : typeGrafic
                    ? `Período ${new Date(new Date(date).setDate(new Date(date).getDate() + 1)).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })}`
                    : `Período ${new Date(new Date(date).setDate(new Date(date).getDate() + 1)).toLocaleDateString('pt-BR', {month: '2-digit', year: 'numeric' })}`,
                
            }]}
            margin={{ left: 55, right: 20, top: 10, bottom: 44 }}
            series={[{ dataKey: 'valorTotal', color: Configuracao.COR_4, valueFormatter: (value) => `${value ? value?.toFixed(2).replace('.', ',') : '0.00'}` }]}
          />
        </Container>
      </Container>

      <Container className="menu">
        <Container className="map">
          <Container 
            className={isSmallScreen ? "options options-mobile" : "options options-default"} 
            open={false}
            onClick={() => handleOpenModalEmissao(-1)}
          >
            <span className="icon">            
              <NoteAddOutlinedIcon style={{fontSize: 40}} />
            </span>
            Emitir Nota
          </Container>

          <Container className={isSmallScreen ? "options options-mobile" : "options options-default"} onClick={() => handleNavigateTo("/notas")}>
            <span className="icon">
              <ListAltOutlinedIcon style={{fontSize: 40}} />
            </span>
            Notas Emitidas
          </Container>
          
          <Container className={isSmallScreen ? "options options-mobile" : "options options-default"} onClick={() => handleNavigateTo("/produtos")}>
            <span className="icon">
              <CategoryOutlinedIcon style={{fontSize: 40}} />
            </span>
            Produtos
          </Container>          
          
          <Container className={isSmallScreen ? "options options-mobile" : "options options-default"} onClick={() => handleNavigateTo("/empresaConfig")}>
            <span className="icon">
              <SettingsOutlinedIcon style={{fontSize: 40}} />                 
            </span>
            Configurações
          </Container>
        </Container>

        <Container className="baseBoard" >
          {(isCertificateExistent && diasCertificadoVencimento <= 15) &&
            <Typography
              style={{
                paddingTop: isSmallScreen ? '0px' : '20px', 
                fontSize: isSmallScreen ? '15px' : '18px', 
                height: isSmallScreen ? '50px' : '0px', 
                flexDirection: 'column',
                alignItems: 'center',
                display: 'flex',
                color: 'red'
              }}>
                {i18n.t("main.expiredCertificate")} {diasCertificadoVencimento} {i18n.t("main.days")}
            </Typography>}
        </Container>
      </Container>
    </Container>
    </>
  );
};

export default Main;