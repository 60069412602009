import React, { createContext, useState } from "react";

import useAuth from "../../hooks/useAuth.js";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {	
	const { loading, user, isAuth, handleLogin, handleLogout, loadingCallback } = useAuth();

	const [drawerOpen, setDrawerOpen] = useState(false);

	return (
		<AuthContext.Provider value={{
			drawerOpen
			, loading
			, user
			, isAuth
			, handleLogin
			, handleLogout
			, loadingCallback
			, setDrawerOpen
		}}>
			{children}
		</AuthContext.Provider>
	);	
};

export { AuthContext, AuthProvider };